import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import ApexCharts from 'apexcharts';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ChartComponent,
} from 'ng-apexcharts';
import { EMPTY, Subscription, tap } from 'rxjs';
import { NavBarCloseService } from 'src/app/services/nav-bar-close.service';
import { ZoningDataService } from 'src/app/services/zoning-data.service';
import { Constants } from 'src/app/shared';
import { AccordionTO } from 'src/app/ui/accordion/accordion.to';
import { SelectTO } from 'src/app/ui/select/select.to';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-zoning-data',
  templateUrl: './zoning-data.component.html',
  styleUrls: ['./zoning-data.component.scss'],
})
export class ZoningDataComponent implements OnInit, OnDestroy {
  /**
   * Accordion config
   */
  mapOptionsZoningData = {
    title: 'Zoning Data',
    showInfo: false,
    noDropdown: false,
    bgWhite: true,
  } as AccordionTO;

  @ViewChild('chart') chart!: ChartComponent;
  chartOptions!: any;
  financialChartOptions!: any;
  floorAreaChartOptions!: any;
  chartFinancialLineOptions!: any;
  series!: ApexNonAxisChartSeries;
  responsive!: ApexResponsive[];
  labels: any;

  /**
   * TO de config campo year.
   */
  yearSelect: SelectTO = {
    id: 'year',
    title: 'year',
    attrLabel: 'yearName',
    transparent: true,
    showInputSearch: false,
    smaller: true,
    elementList: [
      {
        id: 0,
        name: '2019',
      },
      {
        id: 1,
        name: '2020',
      },
      {
        id: 2,
        name: '2021',
      },
      {
        id: 3,
        name: '2022',
      },
      {
        id: 4,
        name: '2023',
      },
      {
        id: 5,
        name: '2024',
      },
    ],
    list: [],
  } as SelectTO;

  infoTypeData = [
    {
      type: Constants.LOT,
      active: true,
    },
    {
      type: Constants.BUILDING,
      active: false,
    },
    {
      type: Constants.FINANCIAL,
      active: false,
    },
  ];

  /**
   * extraninfo
   */
  extraInfo: any[] = [];

  /**
   * lot data list array
   */
  lotDataList: any[] = [];

  /**
   * building data list array
   */
  buildingDataList: any[] = [];

  /**
   * financial data list array
   */
  financialDataList: any[] = [];

  /**
   * Flag tat handle if lot Selected
   */
  lotSelected: boolean = true;
  /**
   * flag that handle the buildingSelected
   */
  buildingSelected: boolean = false;
  /**
   * Flag that validates if total should be visible
   */
  hideTotal!: boolean;

  /**
   * lotAddress title
   */
  lotAddressTitle: string = '';
  /**
   * Var that contains the lotBoroughSub
   */
  lotBoroughSub: string = '';
  /**
   * Var that contains the lotBlockSub: string = '';
   */
  lotBlockSub: string = '';
  /**
   * Var that contains the lotSub: string = '';
   */
  lotSub: string = '';
  /**
   * Var that contains the bbl sub
   */
  lotBblSub: string = '';
  /**
   * ZD complete info variable
   */
  zoningDistrictCompleteInfo: string = '';
  /**
   * Used title
   */
  title = 'Coming Soon';
  /**
   * Flag that validates that loading is activated
   */
  isLoading = false;
  /**
   * Subscription
   */
  subscription!: Subscription;
  dataLand: any[] = [];
  /**
   * property list data
   */
  dataProperty: any[] = [];

  floorAreaList: any[] = [];
  floorAreaAjdList: any[] = [];
  floorAreaMaxList: any[] = [];
  floorAreaMaxIHList: any[] = [];

  regulationsData: any;
  titleFloorAreaList: any[] = [];
  /**
   * Menu icon
   */
  menuIcon =
    '<svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px"><path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"/></svg>';

  /**
   * Flag that validates that financial tab is selected
   */
  financialSelected: boolean = false;

  /**
   * Input that adjust elements when address search is not visible
   */
  @Input() adjustElement: boolean = false;
  /**
   * Input that handles when address search is visible
   */
  @Input() addressSearchIsVisible: boolean = false;
  /**
   * Input that is activated when two containers are active
   */
  @Input() twoContainer: boolean = false;
  /**
   * Input that handles when second row is activated
   */
  @Input() secondRowActive: boolean = false;
  /**
   * Output that validates if the container is opened
   */
  @Output() validateContainersOpened = new EventEmitter<string>();
  /**
   * Output that handle if container is closed
   */
  @Output() closeItem = new EventEmitter<string>();

  /**
   * Constructor
   * @param navBarCloseService navBarCloseService
   * @param zoningDataService zoningDataService
   */
  constructor(
    private navBarCloseService: NavBarCloseService,
    private zoningDataService: ZoningDataService,
    private backendService: BackendService
  ) {
    this.subscription = EMPTY.pipe(tap(() => null)).subscribe();
    this.handleElementSelected('2024');
    this.bblLotZoningInfo = sessionStorage.getItem('bblLotZoningInfo');
  }

  bblLotZoningInfo: any;
  splitZoneValue!: string;

  ngOnInit(): void {
    this.loadLastTabSelected();
    this.subscription = this.zoningDataService.zoningDData.subscribe(
      (data: any) => {
        this.isLoading = true;
        if (!data) {
          this.load();
          this.updateAllInfo();
          this.getRegulationsData(this.lotBblSub);
        } else {
          this.financialDataList = data;
        }
      }
    );
    this.updateAllInfo();
    // this.updateFloorAreaInfo();
  }

  formatValues(value: any): any {
    return Number(value).toLocaleString('en-GB');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  validateNotANumber(value: any): boolean {
    return isNaN(value);
  }

  updateFloorAreaInfo() {
    let arrayList: any;
    let titleList: any;
    let adjustedList: any;
    let maxList: any;
    let maxIHList: any;
    this.floorAreaList = [];
    this.floorAreaAjdList = [];
    this.regulationsData.message.FAR.DIST.forEach((ele: any) => {
      ele.TITLE.forEach((item: any) => {
        arrayList = {
          titleList: ele.TITLE,
          zonedist: item.ZONEDIST || '-',
          overlay: item.OVERLAY,
          portionNum: item.A_SG_P ? item.A_SG_P.toLocaleString('en-GB') : '-',
          splitLot: this.regulationsData.message.FAR.SPLITZONE,
          sfNum: item.A_SG_SF ? item.A_SG_SF.toLocaleString('en-GB') : '-',
          adjustedTotal: this.regulationsData.message.FAR.A_TOTAL || '-',
          bldgarea: this.regulationsData.message.FAR.BLDGAREA || '-',
          existing: this.regulationsData.message.FAR.EXISTING || '-',
          remainInfo: this.regulationsData.message.FAR.REMAIN || '-',
          valuesData: [
            {
              type: 'R (QH)',
              farValue:
                ele?.R_FAR_QH && ele?.R_FAR_QH.includes('&')
                  ? String(ele.R_FAR_QH)
                  : ele?.R_FAR_QH
                  ? Number(ele.R_FAR_QH).toLocaleString('en-GB')
                  : '-',
              zfaValue: ele?.R_ZFA_QH
                ? Number(ele.R_ZFA_QH).toLocaleString('en-GB')
                : '-',
              remainValue:
                this.regulationsData.message.FAR.SPLITZONE === 'false' &&
                ele.R_ZFA_QH
                  ? (
                      Number(ele.R_ZFA_QH) -
                      Number(this.regulationsData.message.FAR.BLDGAREA)
                    ).toLocaleString('en-GB')
                  : '-',
            },
            {
              type: 'R (HF)',
              farValue: ele?.R_FAR_HF
                ? Number(ele.R_FAR_HF).toLocaleString('en-GB')
                : '-',
              zfaValue: ele?.R_FAR_HF
                ? Number(ele.R_ZFA_HF).toLocaleString('en-GB')
                : '-',
              remainValue:
                this.regulationsData.message.FAR.SPLITZONE === 'false' &&
                ele.R_ZFA_HF
                  ? (
                      Number(ele.R_ZFA_HF) -
                      Number(this.regulationsData.message.FAR.BLDGAREA)
                    ).toLocaleString('en-GB')
                  : '-',
            },
            {
              type: 'R (IH)',
              farValue: ele?.R_FAR_IH
                ? Number(ele.R_FAR_IH).toLocaleString('en-GB')
                : '-',
              zfaValue: ele?.R_FAR_IH
                ? Number(ele.R_ZFA_IH).toLocaleString('en-GB')
                : '-',
              remainValue:
                this.regulationsData.message.FAR.SPLITZONE === 'false' &&
                ele.R_ZFA_IH
                  ? (
                      Number(ele.R_ZFA_IH) -
                      Number(this.regulationsData.message.FAR.BLDGAREA)
                    ).toLocaleString('en-GB')
                  : '-',
            },
            {
              type: 'CF',
              farValue: ele?.CF_FAR
                ? Number(ele.CF_FAR).toLocaleString('en-GB')
                : '-',
              zfaValue: ele?.CF_ZFA
                ? Number(ele.CF_ZFA).toLocaleString('en-GB')
                : '-',
              remainValue:
                this.regulationsData.message.FAR.SPLITZONE === 'false' &&
                ele.CF_ZFA
                  ? (
                      Number(ele.CF_ZFA) -
                      Number(this.regulationsData.message.FAR.BLDGAREA)
                    ).toLocaleString('en-GB')
                  : '-',
            },
            {
              type: 'C',
              farValue: ele?.C_FAR
                ? Number(ele.C_FAR).toLocaleString('en-GB')
                : '-',
              zfaValue: ele?.C_ZFA
                ? Number(ele.C_ZFA).toLocaleString('en-GB')
                : '-',
              remainValue:
                this.regulationsData.message.FAR.SPLITZONE === 'false' &&
                ele.C_ZFA
                  ? String(
                      (
                        Number(ele.C_ZFA) -
                        Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
            },
            {
              type: 'M',
              farValue: ele?.M_FAR
                ? Number(ele.M_FAR).toLocaleString('en-GB')
                : '-',
              zfaValue: ele?.M_ZFA
                ? Number(ele.M_ZFA).toLocaleString('en-GB')
                : '-',
              remainValue:
                this.regulationsData.message.FAR.SPLITZONE === 'false' &&
                ele.M_ZFA
                  ? Number(ele.M_ZFA) -
                    Number(this.regulationsData.message.FAR.BLDGAREA)
                  : '-',
            },
          ],
        };
        this.titleFloorAreaList = ele.TITLE;
        adjustedList = [
          {
            zonedist: item.ZONEDIST || '-',
            overlay: item.OVERLAY,
            portionNum: item.A_SG_P || '-',
            splitLot: this.regulationsData.message.FAR.SPLITZONE,
            sfNum: item.A_SG_SF || '-',
            adjustedTotal: this.regulationsData.message.FAR.A_TOTAL || '-',
            bldgarea: this.regulationsData.message.FAR.BLDGAREA || '-',
            existing: this.regulationsData.message.FAR.EXISTING || '-',
            remainInfo: this.regulationsData.message.FAR.REMAIN || '-',
            adjustedData: [
              {
                type: 'R (QH)',
                farValue: this.regulationsData.message.FAR.ADJUSTED.R_ADJ_FARQ
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.R_ADJ_FARQ
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAQ
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAQ
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.ADJUSTED
                  .R_ADJ_ZFAQ
                  ? String(
                      (
                        Number(
                          this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAQ
                        ) - Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
              },
              {
                type: 'R (HF)',
                farValue: this.regulationsData.message.FAR.ADJUSTED.R_ADJ_FARH
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.R_ADJ_FARH
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAH
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAH
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.ADJUSTED
                  .R_ADJ_ZFAH
                  ? String(
                      (
                        Number(
                          this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAH
                        ) - Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
              },
              {
                type: 'R (IH)',
                farValue: this.regulationsData.message.FAR.ADJUSTED.R_FAR_IH
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.R_FAR_IH
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.ADJUSTED.R_ZFA_IH
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.R_ZFA_IH
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.ADJUSTED.R_ZFA_IH
                  ? String(
                      (
                        Number(
                          this.regulationsData.message.FAR.ADJUSTED.R_ZFA_IH
                        ) - Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
              },
              {
                type: 'CF',
                farValue: this.regulationsData.message.FAR.ADJUSTED.CF_ADJ_FAR
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.CF_ADJ_FAR
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.ADJUSTED.CF_ADJ_ZFA
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.CF_ADJ_ZFA
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.ADJUSTED
                  .CF_ADJ_ZFA
                  ? String(
                      (
                        Number(
                          this.regulationsData.message.FAR.ADJUSTED.CF_ADJ_ZFA
                        ) - Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
              },
              {
                type: 'C',
                farValue: this.regulationsData.message.FAR.ADJUSTED.C_ADJ_FAR
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.C_ADJ_FAR
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.ADJUSTED.C_ADJ_ZFA
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.C_ADJ_ZFA
                    ).toLocaleString('en-GB')
                  : '-',

                remainValue: this.regulationsData.message.FAR.ADJUSTED.C_ADJ_ZFA
                  ? String(
                      (
                        Number(
                          this.regulationsData.message.FAR.ADJUSTED.C_ADJ_ZFA
                        ) - Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
              },
              {
                type: 'M',
                farValue: this.regulationsData.message.FAR.ADJUSTED.M_ADJ_FAR
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.M_ADJ_FAR
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.ADJUSTED.M_ADJ_ZFA
                  ? Number(
                      this.regulationsData.message.FAR.ADJUSTED.M_ADJ_ZFA
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.ADJUSTED.M_ADJ_ZFA
                  ? String(
                      (
                        Number(
                          this.regulationsData.message.FAR.ADJUSTED.M_ADJ_ZFA
                        ) - Number(this.regulationsData.message.FAR.BLDGAREA)
                      ).toLocaleString('en-GB')
                    )
                  : '-',
              },
            ],
          },
        ];
        maxList = [
          {
            maxData: [
              {
                type: 'Max',
                farValue: this.regulationsData.message.FAR.MAX[0]
                  ? Number(
                      this.regulationsData.message.FAR.MAX[0]
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.MAX[1]
                  ? Number(
                      this.regulationsData.message.FAR.MAX[1]
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.MAX[1]
                  ? (
                      Number(this.regulationsData.message.FAR.MAX[1]) -
                      Number(this.regulationsData.message.FAR.BLDGAREA)
                    ).toLocaleString('en-GB')
                  : '-',
              },
            ],
          },
        ];
        maxIHList = [
          {
            maxIHData: [
              {
                type: 'Max IH',
                farValue: this.regulationsData.message.FAR.MAX_IH[0]
                  ? Number(
                      this.regulationsData.message.FAR.MAX_IH[0]
                    ).toLocaleString('en-GB')
                  : '-',
                zfaValue: this.regulationsData.message.FAR.MAX_IH[1]
                  ? Number(
                      this.regulationsData.message.FAR.MAX_IH[1]
                    ).toLocaleString('en-GB')
                  : '-',
                remainValue: this.regulationsData.message.FAR.MAX_IH[1]
                  ? (
                      Number(this.regulationsData.message.FAR.MAX_IH[1]) -
                      Number(this.regulationsData.message.FAR.BLDGAREA)
                    ).toLocaleString('en-GB')
                  : '-',
              },
            ],
          },
        ];
      });
      console.log('teeeeest', this.titleFloorAreaList);

      this.floorAreaList.push(arrayList);
      this.titleFloorAreaList.push(titleList);
      if (!!this.regulationsData.message.FAR.ADJUSTED) {
        this.floorAreaAjdList = adjustedList;
        this.floorAreaMaxList = maxList;
        this.floorAreaMaxIHList = maxIHList;
      }
    });
    console.log('dataaaaaaa', this.floorAreaList);

    console.log('okaaaaaaaa', this.floorAreaAjdList);
    this.removeUndefinedFromArray();
  }

  removeUndefinedFromArray() {
    const titleArray = this.titleFloorAreaList.filter(
      (item: any) => item !== undefined
    );
    this.titleFloorAreaList = titleArray;
  }

  /**
   * Method that updates all information
   */
  updateAllInfo() {
    const labelFinancialBarsFormatter = function (value: any) {
      var val: any = Math.abs(value);
      if (val >= 1000) {
        val = (val / 1000).toFixed(0) + ' K';
      }
      return val;
    };

    this.chartOptions = {
      series: [
        {
          name: '',
          data: [
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.resarea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.comarea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.officearea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.retailarea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.garagearea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.strgearea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.factryarea)
            ),
            Number(
              (this.lotAddressTitle = JSON.parse(
                this.bblLotZoningInfo
              )?.otherarea)
            ),
          ],
        },
      ],
      chart: {
        height: 280,
        width: 300,
        type: 'bar',
        events: {
          click: function (chart: any, w: any, e: any) {},
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      title: {
        text: 'Gross Square Feet (sf) per Uses',
        align: 'center',
        style: {
          color: '#FFF',
          fontSize: '12px',
          fontFamily: 'Gotham, sans-serif',
          fontWeight: 500,
        },
      },
      yaxis: {
        tickAmount: 4,
        logarithmic: false,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#FFF',
        },
        labels: {
          style: {
            colors: '#FFF',
          },
          formatter: labelFinancialBarsFormatter,
        },
        title: {
          text: undefined,
          style: {
            color: '#FFF',
          },
        },
      },
      xaxis: {
        colors: ['#fff'],

        categories: [
          ['Residential'],
          ['Commercial'],
          ['Office'],
          ['Retail'],
          ['Garage'],
          ['Storage'],
          ['Factory'],
          ['Other'],
        ],
        labels: {
          show: true,
          rotate: -40,
          style: {
            colors: [
              '#E9BD6A',
              '#D54F41',
              '#D79790',
              '#906C6C',
              '#555555',
              '#BAB8B7',
              '#C570ED',
              '#5099E9',
            ],
            fontSize: '12px',
          },
        },
      },
      colors: [
        '#E9BD6A',
        '#D54F41',
        '#D79790',
        '#906C6C',
        '#555555',
        '#BAB8B7',
        '#C570ED',
        '#5099E9',
      ],
      grid: {
        show: true,
      },
    };

    this.dataLand = [
      {
        x: '2020',
        y: Number(JSON.parse(this.bblLotZoningInfo)?.land2020),
      },
      {
        x: '2021',
        y: JSON.parse(this.bblLotZoningInfo)?.land2021,
      },
      {
        x: '2022',
        y: JSON.parse(this.bblLotZoningInfo)?.land2022,
      },
      {
        x: '2023',
        y: JSON.parse(this.bblLotZoningInfo)?.land2023,
      },
      {
        x: '2024',
        y: JSON.parse(this.bblLotZoningInfo)?.land2024,
      },
    ];

    this.dataProperty = [
      {
        x: '2020',
        y: JSON.parse(this.bblLotZoningInfo)?.prop2020,
      },
      {
        x: '2021',
        y: JSON.parse(this.bblLotZoningInfo)?.prop2021,
      },
      {
        x: '2022',
        y: JSON.parse(this.bblLotZoningInfo)?.prop2022,
      },
      {
        x: '2023',
        y: JSON.parse(this.bblLotZoningInfo)?.prop2023,
      },
      {
        x: '2024',
        y: JSON.parse(this.bblLotZoningInfo)?.prop2024,
      },
    ];

    const labelFinancialLinesFormatter = function (value: any) {
      var val: any = Math.abs(value);
      if (val >= 1000000) {
        val = '$' + '' + (val / 1000000).toFixed(1) + ' M';
      } else if (val >= 1000) {
        val = '$' + '' + (val / 1000).toFixed(0) + ' K';
      }
      return val;
    };

    this.chartFinancialLineOptions = {
      chart: {
        height: 280,
        width: 310,
        type: 'line',
        stacked: false,
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#F0D459', '#CA7266'],
      series: [
        {
          name: '',
          data: this.dataProperty,
        },
        {
          name: '',
          data: this.dataLand,
        },
      ],
      stroke: {
        curve: 'straight',
      },
      grid: {
        show: true,
      },
      plotOptions: {
        bar: {
          columnWidth: '20%',
        },
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'USD ($) per year',
        align: 'center',
        style: {
          color: '#FFF',
          fontSize: '12px',
          fontFamily: 'Gotham, sans-serif',
          fontWeight: 500,
        },
      },
      xaxis: {
        categories: ['2020', '2021', '2022', '2023', '2024'],
        labels: {
          style: {
            colors: '#FFF',
          },
        },
      },
      yaxis: [
        {
          tickAmount: 4,
          logarithmic: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: true,
            color: '#FFF',
          },
          labels: {
            style: {
              colors: '#FFF',
            },
            formatter: labelFinancialLinesFormatter,
          },
          title: {
            text: undefined,
            style: {
              color: '#FFF',
            },
          },
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false,
        },
      },
      legend: {
        show: false,
        horizontalAlign: 'left',
        offsetX: 40,
      },
    };

    this.financialChartOptions = {
      series: [2.96, 3.06],
      chart: {
        type: 'donut',
        height: 280,
        width: 305,
        offsetY: 0,
      },
      stroke: {
        width: 0,
      },
      labels: ['AVAILABLE', 'BUILT'],
      colors: ['#EACE56', '#939295'], // add this part to modify colours
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
          },
        },
      ],
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      dataLabels: {
        // add this part to remove %
        enabled: false,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 30,
          },
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Gotham, sans-serif',
                fontWeight: 700,
                color: undefined,
                offsetY: 20,
                formatter: function (val: any) {
                  return val;
                },
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Gotham, sans-serif',
                fontWeight: 700,
                color: '#F1F1F1',
                offsetY: -20,
                formatter: function (val: any) {
                  return `${Number(val)} FAR`;
                },
              },
              total: {
                show: true,
                showAlways: false,
                label: ['MAX'],
                fontSize: '16px',
                color: '#F1F1F1',
                fontWeight: 700,
                offsetY: 0,
                formatter: (w: any) => {
                  const sum = w.globals.seriesTotals.reduce(
                    (partialSum: any, a: any) => partialSum + a,
                    0
                  );
                  return `${sum} FAR`;
                },
              },
            },
            size: '80%',
          },
        },
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
    };

    var chart = new ApexCharts(
      document.querySelector('#chart'),
      this.chartOptions
    );
    var chart = new ApexCharts(
      document.querySelector('#chartFinancial'),
      this.financialChartOptions
    );
    var chart = new ApexCharts(
      document.querySelector('#chartFinancial'),
      this.chartFinancialLineOptions
    );

    if (
      (!!chart && !!this.financialSelected) ||
      (!!chart && this.buildingSelected)
    ) {
      chart.render();
    }

    this.lotAddressTitle = JSON.parse(this.bblLotZoningInfo)?.address || '-';
    this.lotBoroughSub = JSON.parse(this.bblLotZoningInfo)?.borough || '-';
    this.lotBlockSub = JSON.parse(this.bblLotZoningInfo)?.block || '-';
    this.lotSub = JSON.parse(this.bblLotZoningInfo)?.lot || '-';
    this.lotBblSub = JSON.parse(this.bblLotZoningInfo)?.bbl || '-';
    this.zoningDistrictCompleteInfo = `${
      JSON.parse(this.bblLotZoningInfo)?.zonedist1 +
      (JSON.parse(this.bblLotZoningInfo)?.zonedist2 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.zonedist2}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.zonedist3 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.zonedist3}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.zonedist4 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.zonedist4}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.overlay1 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.overlay1}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.overlay2 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.overlay2}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.spdist1 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.spdist1}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.spdist2 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.spdist2}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.spdist3 !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.spdist3}`
        : '') +
      (JSON.parse(this.bblLotZoningInfo)?.ltdheight !== '-'
        ? `, ${JSON.parse(this.bblLotZoningInfo)?.ltdheight}`
        : '')
    }`;
    this.extraInfo = [
      {
        pdfInfoText: `Zoning Map: ${
          JSON.parse(this.bblLotZoningInfo)?.zonemap
        } (PDF)`,
        pdfInfoUrl: `https://s-media.nyc.gov/agencies/dcp/assets/files/pdf/zoning/zoning-maps/map${
          JSON.parse(this.bblLotZoningInfo)?.zonemap
        }.pdf`,

        buildingInfoText: 'Building Info',
        buildingInfoUrl: `https://a810-bisweb.nyc.gov/bisweb/PropertyBrowseByBBLServlet?allborough=${
          JSON.parse(this.bblLotZoningInfo)?.boroughn
        }&allblock=${JSON.parse(this.bblLotZoningInfo)?.block}&alllot=${
          JSON.parse(this.bblLotZoningInfo)?.lot
        }&go5=+GO+&requestid=0`,

        digitalInfoText: 'Digital Tax Map',
        digitalInfoUrl: `https://propertyinformationportal.nyc.gov/parcels/parcel/${
          JSON.parse(this.bblLotZoningInfo)?.bbl
        }`,

        propertyInfoText: 'Property Records',
        propertyInfoUrl: `http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${
          JSON.parse(this.bblLotZoningInfo)?.boroughn
        }&block=${JSON.parse(this.bblLotZoningInfo)?.block}&lot=${
          JSON.parse(this.bblLotZoningInfo)?.lot
        }`,
      },
    ];

    this.lotDataList = [
      {
        title: 'Lot Data',
        type: Constants.LOT,
        info: [
          {
            title: 'Owner Data ',
            info: 'Owner Name',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.ownername || '-',
            width: '124px',
            breakValue:
              JSON.parse(this.bblLotZoningInfo)?.ownername.length > 28
                ? false
                : true,
          },
          {
            info: 'Type of Ownership',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.zd_ownertype || '-',
            width: '124px',
            breakValue: false,
            noBorder: true,
          },
          {
            title: 'Property Data ',
            info: 'Address',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.address || '-',
            width: '124px',
            breakValue: true,
          },
          {
            info: 'Borough',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.borough || '-',
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Block',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.block || '-',
            width: '48px',
            breakValue: false,
          },
          {
            info: 'Lot',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.lot || '-',
            width: '48px',
            breakValue: false,
          },
          {
            info: 'Community District',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.cd || '-',
            width: '48px',
            breakValue: false,
            noBorder: true,
          },
          {
            title: 'Geometry',
            info: 'Lot Area',
            url: null,
            value:
              `${Number(
                JSON.parse(this.bblLotZoningInfo)?.lotarea
              ).toLocaleString('en-GB')} sf` || '-',
            width: '48px',
            breakValue: false,
          },
          {
            info: 'Lot Frontage',
            url: null,
            value:
              `${Number(
                JSON.parse(this.bblLotZoningInfo)?.lotfront
              ).toLocaleString('en-GB')} ft` || '-',
            width: '48px',
            breakValue: false,
          },
          {
            info: 'Lot Depth',
            url: null,
            value:
              `${Number(
                JSON.parse(this.bblLotZoningInfo)?.lotdepth
              ).toLocaleString('en-GB')} ft` || '-',
            width: '48px',
            breakValue: false,
          },
          {
            info: 'Irregular Lot',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.irrlotcode || '-',
            width: '48px',
            breakValue: false,
          },

          {
            info: 'Lot Type',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.zd_lottype || '-',
            width: '124px',
            breakValue: false,
            noBorder: true,
          },
          {
            title: 'Zoning layers',
            info: 'Zoning District',
            url: null,
            value:
              `${
                JSON.parse(this.bblLotZoningInfo)?.zonedist1 === '-'
                  ? ''
                  : JSON.parse(this.bblLotZoningInfo)?.zonedist1
              }` +
                `${
                  JSON.parse(this.bblLotZoningInfo)?.zonedist2 === '-'
                    ? ''
                    : ', ' + JSON.parse(this.bblLotZoningInfo)?.zonedist2
                }` +
                `${
                  JSON.parse(this.bblLotZoningInfo)?.zonedist3 === '-'
                    ? ''
                    : ', ' + JSON.parse(this.bblLotZoningInfo)?.zonedist3
                }` +
                `${
                  JSON.parse(this.bblLotZoningInfo)?.zonedist4 === '-'
                    ? ''
                    : ', ' + JSON.parse(this.bblLotZoningInfo)?.zonedist4
                }` || '-',
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Overlay District',
            url: null,
            value:
              `${
                JSON.parse(this.bblLotZoningInfo)?.overlay1 === '-'
                  ? '-'
                  : JSON.parse(this.bblLotZoningInfo)?.overlay1
              }` +
              `${
                JSON.parse(this.bblLotZoningInfo)?.overlay2 === '-'
                  ? ''
                  : ', ' + JSON.parse(this.bblLotZoningInfo)?.overlay2
              }`,
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Special District',
            url: null,
            value:
              `${
                JSON.parse(this.bblLotZoningInfo)?.spdist1 === '-'
                  ? ''
                  : JSON.parse(this.bblLotZoningInfo)?.spdist1
              }` +
                `${
                  JSON.parse(this.bblLotZoningInfo)?.spdist2 === '-'
                    ? ''
                    : ', ' + JSON.parse(this.bblLotZoningInfo)?.spdist2
                }` +
                `${
                  JSON.parse(this.bblLotZoningInfo)?.spdist3 === '-'
                    ? ''
                    : ', ' + JSON.parse(this.bblLotZoningInfo)?.spdist3
                }` || '-',
            width: '100px',
            breakValue: false,
          },
          {
            info: 'Limited Height District',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.ltdheight || '-',
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Split District Lot',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.splitzone || '-',
            width: '124px',
            breakValue: false,
            noBorder: true,
          },
          {
            title: 'Additional layers',
            info: 'Flood Zone',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.flood_zone || '-',
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Historic District',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.histdist,
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Landmark',
            url: JSON.parse(this.bblLotZoningInfo)?.landurl,
            value: JSON.parse(this.bblLotZoningInfo)?.zd_landmark,
            width: '124px',
            breakValue: false,
          },
          {
            info: 'Environmental Designation',
            url: 'https://zr.planning.nyc.gov/appendix-c-table-1-city-environmental-quality-review-ceqr-environmental-requirements-e-designations',
            value: JSON.parse(this.bblLotZoningInfo)?.edesignum,
            width: '124px',
            breakValue: false,
            warningIcon: true,
          },
        ],
      },
    ];

    this.buildingDataList = [
      {
        title: 'Building Data',
        type: Constants.BUILDING,
        zd_areasource: JSON.parse(this.bblLotZoningInfo)?.zd_areasource || '-',
        info: [
          {
            title: 'Building Data',
            info: 'Land Use Category',
            url: null,
            value: JSON.parse(this.bblLotZoningInfo)?.zd_landuse || '-',
            width: '100px',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            breakValue: false,
          },
          {
            info: 'Building Class',
            value: JSON.parse(this.bblLotZoningInfo)?.zd_bldgclass || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '100px',
          },
          {
            info: 'Year built',
            value: JSON.parse(this.bblLotZoningInfo)?.yearbuilt || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '48px',
          },
          {
            info: 'Year Altered',
            value: JSON.parse(this.bblLotZoningInfo)?.year_altered || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '100px',
          },
          {
            info: 'Number of buildings',
            value: JSON.parse(this.bblLotZoningInfo)?.numbldgs || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '48px',
          },
          {
            info: 'Number of floors',
            value: JSON.parse(this.bblLotZoningInfo)?.numfloors || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '48px',
          },
          {
            info: 'Basement Type/Grade',
            value: JSON.parse(this.bblLotZoningInfo)?.zd_bsmtcode || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '124px',
          },
          {
            info: 'Residential Units',
            value: JSON.parse(this.bblLotZoningInfo)?.unitsres || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '124px',
          },
          {
            info: 'Total Units',
            value: JSON.parse(this.bblLotZoningInfo)?.unitstotal || '-',
            totalLabel: null,
            totalValue: null,
            showChart: false,
            width: '124px',
            noBorder: true,
          },
          {
            title: 'Floor Area Distribution',
            info: 'Building Gross sf',
            value:
              Number(
                JSON.parse(this.bblLotZoningInfo)?.bldgarea
              ).toLocaleString('en-GB') +
                ' ' +
                'sf' || '-',
            innerInfo: true,
            totalLabel: 'Building Gross',
            totalValue: `${JSON.parse(this.bblLotZoningInfo)?.bldgarea} sf`,
            showChart: true,
            showIcon: true,
            innerData: [
              {
                info: 'Residential Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.resarea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#E9BD6A',
              },
              {
                info: 'Commercial Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.comarea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#D54F41',
              },
              {
                info: 'Office Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.officearea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#D79790',
              },
              {
                info: 'Retail Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.retailarea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#906C6C',
              },
              {
                info: 'Garage Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.garagearea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#555',
              },
              {
                info: 'Storage Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.strgearea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#BAB8B7',
              },
              {
                info: 'Factory Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.factryarea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#C570ED',
              },
              {
                info: 'Other Floor Area',
                value:
                  Number(
                    JSON.parse(this.bblLotZoningInfo)?.otherarea
                  ).toLocaleString('en-GB') +
                    ' ' +
                    'sf' || '-',
                color: '#5099E9',
              },
            ],
          },
        ],
      },
    ];

    this.financialDataList = [
      {
        title: 'Financial Data',
        type: Constants.FINANCIAL,
        showChart: true,
        totalValue: '6.5 FAR',
        totalLabel: 'MAX',
        info: [
          {
            info: 'Year of File',
            value: '2010',
            hasSelect: true,
          },
          {
            info: 'Tax Class',
            value: JSON.parse(this.bblLotZoningInfo)?.taxc2024 || '-',
            showIcon: true,
          },
          {
            info: 'Property Market Value ($)',
            value:
              `$ ${Number(
                JSON.parse(this.bblLotZoningInfo)?.prop2024
              ).toLocaleString('en-GB')}` || '-',
            subData: 'Property Cost/sf  ($)',
            subValue: `$ ${Number(
              JSON.parse(this.bblLotZoningInfo)?.propc2024
            ).toLocaleString('en-GB')} /sf`,
            colorBox: '#F0D459',
            hasFinancialBars: false,
          },
          {
            info: 'Land Market Value ($)',

            value:
              `$ ${Number(
                JSON.parse(this.bblLotZoningInfo)?.land2024
              ).toLocaleString('en-GB')}` || '-',
            subData: ' Land Cost/sf ($)',
            subValue: `$ ${Number(
              JSON.parse(this.bblLotZoningInfo)?.landc2024
            ).toLocaleString('en-GB')} /sf`,
            colorBox: '#CA7266',
            hasFinancialBars: true,
          },
        ],
      },
    ];
  }

  /**
   * Method that emit and validates the container opened
   */
  validateContainer() {
    this.validateContainersOpened.emit('ZoningData');
  }

  /**
   * Method that remember and load the last tab selected
   */
  loadLastTabSelected() {
    const lotTab = sessionStorage.getItem('lotTabZD');
    const buildingTabZD = sessionStorage.getItem('buildingTabZD');
    const financialTab = sessionStorage.getItem('financialTabZD');
    this.infoTypeData.forEach((element) => {
      element.active = false;
    });
    this.lotSelected = false;
    this.buildingSelected = false;
    this.financialSelected = false;

    if (lotTab === 'true' || (!lotTab && !buildingTabZD && !financialTab)) {
      this.lotSelected = true;
      this.infoTypeData[0].active = !this.infoTypeData[0].active;
    } else if (buildingTabZD) {
      this.buildingSelected = true;
      this.infoTypeData[1].active = !this.infoTypeData[1].active;
    } else if (financialTab) {
      this.financialSelected = true;
      this.infoTypeData[2].active = !this.infoTypeData[2].active;
    }
  }

  /**
   * Method that handles the tab selected
   * @param index index value
   */
  handleTab(index: number) {
    this.infoTypeData.forEach((element) => {
      element.active = false;
    });
    this.lotSelected = false;
    this.buildingSelected = false;
    this.financialSelected = false;
    switch (this.infoTypeData[index].type) {
      case Constants.LOT:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.lotSelected = this.infoTypeData[index].active;
        sessionStorage.setItem('lotTabZD', String(this.lotSelected));
        sessionStorage.setItem('buildingTabZD', '');
        sessionStorage.setItem('financialTabZD', '');
        break;

      case Constants.BUILDING:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.buildingSelected = this.infoTypeData[index].active;
        sessionStorage.setItem('buildingTabZD', String(this.buildingSelected));
        sessionStorage.setItem('lotTabZD', String(''));
        sessionStorage.setItem('financialTabZD', '');

        break;

      case Constants.FINANCIAL:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.financialSelected = this.infoTypeData[index].active;
        sessionStorage.setItem('financialTabZD', String(this.buildingSelected));
        sessionStorage.setItem('lotTabZD', String(''));
        sessionStorage.setItem('buildingTabZD', '');
        this.getRegulationsData(this.lotBblSub);
        break;

      default:
        break;
    }
  }

  updateFloorAreaGraphic() {
    if (this.floorAreaAjdList) {
      this.floorAreaChartOptions = {
        series: [
          {
            name: '',
            data: [
              this.regulationsData.message.FAR.SPLITZONE === 'true'
                ? this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAQ
                : Number(this.regulationsData.message.FAR.DIST[0].R_ZFA_QH),
              0,
              0,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: '',
            data: [
              0,
              this.regulationsData.message.FAR.SPLITZONE === 'true'
                ? this.regulationsData.message.FAR.ADJUSTED.R_ZFA_HF ||
                  this.regulationsData.message.FAR.ADJUSTED.R_ADJ_ZFAH
                : this.regulationsData.message.FAR.DIST[0].R_ZFA_HF,
              0,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: '',
            data: [
              0,
              0,
              this.regulationsData.message.FAR.SPLITZONE === 'true'
                ? this.regulationsData.message.FAR.ADJUSTED.R_ZFA_IH
                : this.regulationsData.message.FAR.DIST[0].R_ZFA_IH,
              0,
              0,
              0,
              0,
            ],
          },
          {
            name: '',
            data: [
              0,
              0,
              0,
              this.regulationsData.message.FAR.SPLITZONE === 'true'
                ? Math.round(
                    (this.regulationsData.message.FAR.ADJUSTED.CF_ADJ_ZFA +
                      Number.EPSILON) *
                      100
                  ) / 100
                : Math.round(
                    (this.regulationsData.message.FAR.DIST[0].CF_ZFA +
                      Number.EPSILON) *
                      100
                  ) / 100,
              0,
              0,
              0,
            ],
          },
          {
            name: '',
            data: [
              0,
              0,
              0,
              0,
              this.regulationsData.message.FAR.SPLITZONE === 'true'
                ? this.regulationsData.message.FAR.ADJUSTED.C_ADJ_ZFA
                : this.regulationsData.message.FAR.DIST[0].C_ZFA,
              0,
              0,
            ],
          },
          {
            name: '',
            data: [
              0,
              0,
              0,
              0,
              0,
              this.regulationsData.message.FAR.SPLITZONE === 'true'
                ? this.regulationsData.message.FAR.ADJUSTED.M_ADJ_ZFA
                : this.regulationsData.message.FAR.DIST[0].M_ZFA,
              0,
            ],
          },
          {
            name: 'E',
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              this.regulationsData.message.FAR.EXISTING < 0
                ? 0
                : Number(this.regulationsData.message.FAR.EXISTING),
            ],
          },
          {
            name: 'R',
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              this.regulationsData.message.FAR.REMAIN < 0
                ? 0
                : Number(this.regulationsData.message.FAR.REMAIN),
            ],
          },
        ],
        chart: {
          height: 280,
          width: 300,
          type: 'bar',
          events: {
            click: function (chart: any, w: any, e: any) {},
          },
          stacked: true,
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        title: {
          text: '',
          align: 'center',
          style: {
            color: '#FFF',
            fontSize: '12px',
            fontFamily: 'Gotham, sans-serif',
            fontWeight: 300,
          },
        },
        yaxis: {
          tickAmount: 4,
          logarithmic: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: true,
            color: '#FFF',
          },
          labels: {
            style: {
              colors: '#FFF',
            },
            formatter: function (value: any) {
              if (value >= 1000000) {
                const factor = Math.pow(10, 2);
                value = Math.floor((value / 1000000) * factor) / factor;
                return value + ' M';
              } else if (value >= 1000) {
                const factor = Math.pow(10, 2);
                value = Math.floor((value / 1000) * factor) / factor;
                return value + ' k';
              }
              return value;
            },
          },
          title: {
            text: undefined,
            style: {
              color: '#FFF',
            },
          },
        },
        xaxis: {
          colors: ['#fff'],
          categories: [
            ['R', '(QH)'],
            ['R', '(HF)'],
            ['R', '(IH)'],
            ['CF'],
            ['C'],
            ['M'],
            this.regulationsData.message.FAR.EXISTING > 0 &&
            this.regulationsData.message.FAR.REMAIN < 0
              ? ['E']
              : this.regulationsData.message.FAR.REMAIN > 0 &&
                this.regulationsData.message.FAR.EXISTING < 0
              ? ['R']
              : this.regulationsData.message.FAR.EXISTING > 0 &&
                this.regulationsData.message.FAR.REMAIN > 0
              ? ['E/R']
              : ['E/R'],
          ],
          labels: {
            show: true,
            rotate: 0,
            style: {
              colors: [
                '#e8ce9d',
                '#E9BD6A',
                '#d9981f',
                '#5099e9',
                '#d54f41',
                '#c570ed',
                '#BAB8B7',
              ],
              fontSize: '12px',
            },
          },
        },
        colors: [
          '#e8ce9d',
          '#E9BD6A',
          '#d9981f',
          '#5099e9',
          '#d54f41',
          '#c570ed',
          '#BAB8B7',
          '#cee741',
        ],
        grid: {
          show: true,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val.toFixed(2);
            },
          },
        },
      };

      var chart = new ApexCharts(
        document.querySelector('#chartFinancial'),
        this.floorAreaChartOptions
      );
    }
  }

  getRegulationsData(bbl: any) {
    this.backendService.getDataFromRegulations(bbl).subscribe(
      (res: any) => {
        console.log(res, 'data Regulations');
        this.regulationsData = res;
        this.updateFloorAreaInfo();
        this.updateFloorAreaGraphic();
      },
      (err: any) => {}
    );
  }

  /**
   * Method that manage to redirect to the obtained url
   * @param url url to redirect
   */
  redirectToUrl(url?: string) {
    if (url && url.length > 2) {
      window.open(url, '_blank');
    }
  }

  /**
   * Method that close the nav option selected
   */
  close() {
    this.navBarCloseService.turnOffOption(Constants.ZONING_DATA);
    this.closeItem.emit(Constants.ZONING_DATA);
  }

  /**
   *
   * @param event event value
   */
  expandSelectedElement(event: any) {
    if (!event && !this.yearSelect.expanded) {
      this.yearSelect.expanded = true;
    } else if (!!event && !!this.yearSelect.expanded) {
      this.yearSelect.expanded = false;
    } else {
      this.yearSelect.expanded = event;
    }
  }

  /**
   * Method that load the bbl number in memory
   */
  load() {
    const bblLotinfo: any = sessionStorage.getItem('bblLotZoningInfo');
    const bbl1 = JSON.parse(this.bblLotZoningInfo)?.bbl;
    let bbl2;
    if (bblLotinfo) {
      bbl2 = JSON.parse(bblLotinfo)?.bbl;
    }
    this.bblLotZoningInfo = sessionStorage.getItem('bblLotZoningInfo');
    this.splitZoneValue = JSON.parse(this.bblLotZoningInfo)?.splitzone;
    if (bbl1 !== bbl2) {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }

  /**
   * Method that handles the eleement selected
   * @param event event value
   */
  handleElementSelected(event: any) {
    this.yearSelect.title = event;
    if (!!this.bblLotZoningInfo) {
      switch (event) {
        case '2024':
          this.financialDataList = [
            {
              title: 'Financial Data',
              type: Constants.FINANCIAL,
              showChart: true,
              totalValue: '6.5 FAR',
              totalLabel: 'MAX',
              info: [
                {
                  info: 'Year of File',
                  value: '2024',
                  hasSelect: true,
                },
                {
                  info: 'Tax Class',
                  value:
                    JSON.parse(this.bblLotZoningInfo)?.taxc2024 || '-' || '-',
                  showIcon: true,
                },
                {
                  info: 'Property Market Value ($)',
                  value:
                    `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.prop2024
                    ).toLocaleString('en-GB')}` || '-',
                  subData: 'Property Cost/sf  ($)',
                  subValue: `$ ${Number(
                    JSON.parse(this.bblLotZoningInfo)?.propc2024
                  ).toLocaleString('en-GB')} /sf`,
                  colorBox: '#F0D459',
                  hasFinancialBars: false,
                },
                {
                  info: 'Land Market Value ($)',
                  value:
                    `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.land2024
                    ).toLocaleString('en-GB')} ` || '-',
                  subData: ' Land Cost/sf ($)',
                  subValue: `$ ${Number(
                    JSON.parse(this.bblLotZoningInfo)?.landc2024
                  ).toLocaleString('en-GB')} /sf`,
                  colorBox: '#CA7266',
                  hasFinancialBars: true,
                },

                // {
                //   title: 'Floor Area Data',
                //   info: 'Total Max FAR',
                //   value: '6.5',
                //   subData: 'Total Max ZFA',
                //   subValue: '278,564 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                //   showIconInfo: true,
                //   warningText:
                //     'Zoning Lots Divided by District Boundaries show adjusted max floor area per ZR 77-22',
                // },
                // {
                //   info: 'Max FAR Residential (R)',
                //   value: '6.5',
                //   subData: 'Max ZFA As-of-Right (R)',
                //   subValue: '257,993.12 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   info: 'Max Community Facility (CF)',
                //   value: '6.5',
                //   subData: 'Max ZFA As-of-Right (CF)',
                //   subValue: '278,564 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   info: 'Max FAR Commercial (C)',
                //   value: '6',
                //   subData: 'Max ZFA As-of-Right (C)',
                //   subValue: '257,136 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   info: 'Max FAR Manufacturing (M)',
                //   value: 'N/A',
                //   subData: 'Max ZFA As-of-Right (M)',
                //   subValue: 'N/A',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   color: '#939295',
                //   info: 'Built FAR *',
                //   value: '2.96',
                //   subData: 'Built ZFA *',
                //   subValue: '151,820 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   color: '#EACE56',
                //   info: 'Potential Air Rights (FAR)**',
                //   value: '3.06',
                //   subData: 'Potential Air Rights (ZFA)**',
                //   subValue: '151,820 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
              ],
            },
          ];
          break;

        case '2023':
          {
            this.financialDataList = [
              {
                title: 'Financial Data',
                type: Constants.FINANCIAL,
                showChart: true,
                totalValue: '6.5 FAR',
                totalLabel: 'MAX',
                info: [
                  {
                    info: 'Year of File',
                    value: '2023',
                    hasSelect: true,
                  },
                  {
                    info: 'Tax Class',
                    value:
                      JSON.parse(this.bblLotZoningInfo)?.taxc2023 || '-' || '-',
                    showIcon: true,
                  },
                  {
                    info: 'Property Market Value ($)',
                    value:
                      `$ ${Number(
                        JSON.parse(this.bblLotZoningInfo)?.prop2023
                      ).toLocaleString('en-GB')}` || '-',
                    subData: 'Property Cost/sf  ($)',
                    subValue: `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.propc2023
                    ).toLocaleString('en-GB')} /sf`,
                    colorBox: '#F0D459',
                    hasFinancialBars: false,
                  },
                  {
                    info: 'Land Market Value ($)',
                    value:
                      `$ ${Number(
                        JSON.parse(this.bblLotZoningInfo)?.land2023
                      ).toLocaleString('en-GB')} ` || '-',
                    subData: ' Land Cost/sf ($)',
                    subValue: `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.landc2023
                    ).toLocaleString('en-GB')} /sf`,
                    colorBox: '#CA7266',
                    hasFinancialBars: true,
                  },

                  // {
                  //   title: 'Floor Area Data',
                  //   info: 'Total Max FAR',
                  //   value: '6.5',
                  //   subData: 'Total Max ZFA',
                  //   subValue: '278,564 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  //   showIconInfo: true,
                  //   warningText:
                  //     'Zoning Lots Divided by District Boundaries show adjusted max floor area per ZR 77-22',
                  // },
                  // {
                  //   info: 'Max FAR Residential (R)',
                  //   value: '6.5',
                  //   subData: 'Max ZFA As-of-Right (R)',
                  //   subValue: '257,993.12 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   info: 'Max Community Facility (CF)',
                  //   value: '6.5',
                  //   subData: 'Max ZFA As-of-Right (CF)',
                  //   subValue: '278,564 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   info: 'Max FAR Commercial (C)',
                  //   value: '6',
                  //   subData: 'Max ZFA As-of-Right (C)',
                  //   subValue: '257,136 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   info: 'Max FAR Manufacturing (M)',
                  //   value: 'N/A',
                  //   subData: 'Max ZFA As-of-Right (M)',
                  //   subValue: 'N/A',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   color: '#939295',
                  //   info: 'Built FAR *',
                  //   value: '2.96',
                  //   subData: 'Built ZFA *',
                  //   subValue: '151,820 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },

                  // {
                  //   color: '#EACE56',
                  //   info: 'Potential Air Rights (FAR)**',
                  //   value: '3.06',
                  //   subData: 'Potential Air Rights (ZFA)**',
                  //   subValue: '151,820 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                ],
              },
            ];
          }
          break;

        case '2022':
          this.financialDataList = [
            {
              title: 'Financial Data',
              type: Constants.FINANCIAL,
              showChart: true,
              totalValue: '6.5 FAR',
              totalLabel: 'MAX',
              info: [
                {
                  info: 'Year of File',
                  value: '2022',
                  hasSelect: true,
                },
                {
                  info: 'Tax Class',
                  value:
                    JSON.parse(this.bblLotZoningInfo)?.taxc2022 || '-' || '-',
                  showIcon: true,
                },
                {
                  info: 'Property Market Value ($)',
                  value:
                    `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.prop2022
                    ).toLocaleString('en-GB')}` || '-',
                  subData: 'Property Cost/sf  ($)',
                  subValue: `$ ${Number(
                    JSON.parse(this.bblLotZoningInfo)?.propc2022
                  ).toLocaleString('en-GB')} /sf`,
                  colorBox: '#F0D459',
                  hasFinancialBars: false,
                },
                {
                  info: 'Land Market Value ($)',
                  value:
                    `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.land2022
                    ).toLocaleString('en-GB')}` || '-',
                  subData: ' Land Cost/sf ($)',
                  subValue: `$ ${Number(
                    JSON.parse(this.bblLotZoningInfo)?.landc2022
                  ).toLocaleString('en-GB')} /sf`,
                  colorBox: '#CA7266',
                  hasFinancialBars: true,
                },

                // {
                //   title: 'Floor Area Data',
                //   info: 'Total Max FAR',
                //   value: '6.5',
                //   subData: 'Total Max ZFA',
                //   subValue: '278,564 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                //   showIconInfo: true,
                //   warningText:
                //     'Zoning Lots Divided by District Boundaries show adjusted max floor area per ZR 77-22',
                // },
                // {
                //   info: 'Max FAR Residential (R)',
                //   value: '6.5',
                //   subData: 'Max ZFA As-of-Right (R)',
                //   subValue: '257,993.12 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   info: 'Max Community Facility (CF)',
                //   value: '6.5',
                //   subData: 'Max ZFA As-of-Right (CF)',
                //   subValue: '278,564 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   info: 'Max FAR Commercial (C)',
                //   value: '6',
                //   subData: 'Max ZFA As-of-Right (C)',
                //   subValue: '257,136 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   info: 'Max FAR Manufacturing (M)',
                //   value: 'N/A',
                //   subData: 'Max ZFA As-of-Right (M)',
                //   subValue: 'N/A',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
                // {
                //   color: '#939295',
                //   info: 'Built FAR *',
                //   value: '2.96',
                //   subData: 'Built ZFA *',
                //   subValue: '151,820 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },

                // {
                //   color: '#EACE56',
                //   info: 'Potential Air Rights (FAR)**',
                //   value: '3.06',
                //   subData: 'Potential Air Rights (ZFA)**',
                //   subValue: '151,820 sf',
                //   colorBox: null,
                //   hasFinancialBars: false,
                // },
              ],
            },
          ];
          break;

        case '2021':
          {
            this.financialDataList = [
              {
                title: 'Financial Data',
                type: Constants.FINANCIAL,
                showChart: true,
                totalValue: '6.5 FAR',
                totalLabel: 'MAX',
                info: [
                  {
                    info: 'Year of File',
                    value: '2021',
                    hasSelect: true,
                  },
                  {
                    info: 'Tax Class',
                    value:
                      JSON.parse(this.bblLotZoningInfo)?.taxc2021 || '-' || '-',
                    showIcon: true,
                  },
                  {
                    info: 'Property Market Value ($)',
                    value:
                      `$ ${Number(
                        JSON.parse(this.bblLotZoningInfo)?.prop2021
                      ).toLocaleString('en-GB')}` || '-',
                    subData: 'Property Cost/sf  ($)',
                    subValue: `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.propc2021
                    ).toLocaleString('en-GB')} /sf`,
                    colorBox: '#F0D459',
                    hasFinancialBars: false,
                  },
                  {
                    info: 'Land Market Value ($)',
                    value:
                      `$ ${Number(
                        JSON.parse(this.bblLotZoningInfo)?.land2021
                      ).toLocaleString('en-GB')}` || '-',
                    subData: ' Land Cost/sf ($)',
                    subValue: `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.landc2021
                    ).toLocaleString('en-GB')} /sf`,
                    colorBox: '#CA7266',
                    hasFinancialBars: true,
                  },

                  // {
                  //   title: 'Floor Area Data 000000000',
                  //   info: 'Total Max FAR',
                  //   value: '6.5',
                  //   subData: 'Total Max ZFA',
                  //   subValue: '278,564 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  //   showIconInfo: true,
                  //   warningText:
                  //     'Zoning Lots Divided by District Boundaries show adjusted max floor area per ZR 77-22',
                  // },
                  // {
                  //   info: 'Max FAR Residential (R)',
                  //   value: '6.5',
                  //   subData: 'Max ZFA As-of-Right (R)',
                  //   subValue: '257,993.12 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   info: 'Max Community Facility (CF)',
                  //   value: '6.5',
                  //   subData: 'Max ZFA As-of-Right (CF)',
                  //   subValue: '278,564 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   info: 'Max FAR Commercial (C)',
                  //   value: '6',
                  //   subData: 'Max ZFA As-of-Right (C)',
                  //   subValue: '257,136 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   info: 'Max FAR Manufacturing (M)',
                  //   value: 'N/A',
                  //   subData: 'Max ZFA As-of-Right (M)',
                  //   subValue: 'N/A',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   color: '#939295',
                  //   info: 'Built FAR *',
                  //   value: '2.96',
                  //   subData: 'Built ZFA *',
                  //   subValue: '151,820 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                  // {
                  //   color: '#EACE56',
                  //   info: 'Potential Air Rights (FAR)**',
                  //   value: '3.06',
                  //   subData: 'Potential Air Rights (ZFA)**',
                  //   subValue: '151,820 sf',
                  //   colorBox: null,
                  //   hasFinancialBars: false,
                  // },
                ],
              },
            ];
          }
          break;

        case '2020':
          {
            this.financialDataList = [
              {
                title: 'Financial Data',
                type: Constants.FINANCIAL,
                showChart: true,
                totalValue: '6.5 FAR',
                totalLabel: 'MAX',
                info: [
                  {
                    info: 'Year of File',
                    value: '2020',
                    hasSelect: true,
                  },
                  {
                    info: 'Tax Class',
                    value:
                      JSON.parse(this.bblLotZoningInfo)?.taxc2020 || '-' || '-',
                    showIcon: true,
                  },
                  {
                    info: 'Property Market Value ($)',
                    value:
                      `$ ${Number(
                        JSON.parse(this.bblLotZoningInfo)?.prop2020
                      ).toLocaleString('en-GB')}` || '-',
                    subData: 'Property Cost/sf  ($)',
                    subValue: `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.propc2020
                    ).toLocaleString('en-GB')} /sf`,
                    colorBox: '#F0D459',
                    hasFinancialBars: false,
                  },
                  {
                    info: 'Land Market Value ($)',
                    value:
                      `$ ${Number(
                        JSON.parse(this.bblLotZoningInfo)?.land2020
                      ).toLocaleString('en-GB')}` || '-',
                    subData: ' Land Cost/sf ($)',
                    subValue: `$ ${Number(
                      JSON.parse(this.bblLotZoningInfo)?.landc2020
                    ).toLocaleString('en-GB')} /sf`,
                    colorBox: '#CA7266',
                    hasFinancialBars: true,
                  },
                ],
              },
            ];
          }
          break;

        default:
          break;
      }
      this.zoningDataService.setFinancialInfo(this.financialDataList);
      this.yearSelect.expanded = false;
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  }
}
