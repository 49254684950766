import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


type URLData = { [section: string]: string }; // Definición del tipo
@Component({
  selector: 'app-use-table',
  templateUrl: './use-table.component.html',
  styleUrls: ['./use-table.component.scss']
})
export class UseTableComponent implements OnInit {
  
  ID_INDEX: number = 0;
  csvData: string[][] = [];
  headers: string[] = [];
  newCsvData: string[][] = [];  
  overlayZonedistList: { OVERLAY: string, ZONEDIST: string }[] = [];  
  columnsToCopy: string[] = ['ID', 'Use'];  
  newHeaders: string[] = []
  selectedZonedist: string = ''
  urlData: URLData = {};
  bbl: any;
  

  public activeModal!: NgbActiveModal;
  constructor(private http: HttpClient,activeModal: NgbActiveModal) {
    this.activeModal = activeModal;
  }

  ngOnInit(): void {
    this.bbl = sessionStorage.getItem('bbl');
    this.loadCSV();
    this.loadUrlData()
  }

  loadCSV(): void {
    this.http.get('assets/COY_Use_MASTER.csv', { responseType: 'text' }).subscribe(
      (data) => {
        this.parseCSV(data);
      },
      (error) => {
        console.error('Error loading the CSV file', error);
      }
    );
  }
  closeModal() {
    this.activeModal.close(); 
  }

  loadUrlData(): void {
    this.http.get('assets/COY_Dictionary.csv', { responseType: 'text' }).subscribe(
      (data) => {
        this.urlData = this.processCsvData(data);
        console.log(this.urlData); // Ahora puedes usar el CSV procesado
      },
      (error) => {
        console.error('Error al cargar el archivo CSV', error);
      }
    );
  }
  
  processCsvData(csvText: string): { [section: string]: string } {
    const rows = csvText.split('\n');
    const result: { [section: string]: string } = {};
  
    rows.slice(1).forEach(row => {
      const columns = row.split(';');
  
      // Verificar que hay al menos dos columnas antes de procesar
      if (columns.length >= 2) {
        const section = columns[0].trim();
        const url = columns[1].trim();
        result[section] = url;
      }
    });
  
    return result;
  }

  parseCSV(csv: string): void {
    const lines = csv.split('\n');
    this.csvData = lines.map(line => line.split(';'));
    this.headers = this.csvData[0];  

    this.applyOverlayZonedistLogic();
  }
  
  applyOverlayZonedistLogic(): void {
    // Recorrer overlayZonedistList y aplicar la lógica
    this.overlayZonedistList.forEach(obj => {
      const overlayValue = obj.OVERLAY;
      const zonedistValue = obj.ZONEDIST;

      if (overlayValue && overlayValue !== 'false') {  // Si OVERLAY no es 'false'
        const columnPrefix = overlayValue.slice(0, 2).toUpperCase();  // Obtener los dos primeros caracteres y convertir a mayúscula
        if(this.headers.includes(columnPrefix)){
          this.newHeaders.push(`${zonedistValue}/${overlayValue}`)
          this.columnsToCopy.push(columnPrefix);  // Agregar la columna con el prefijo a la lista
        }
        
      } else if (zonedistValue) {  // Si OVERLAY es false, usar ZONEDIST
        const uniqueValues = [
          'R10H',	'R11',	'R12','M1-1D',	'M1-2D',	'M1-3D',	'M1-4D',	'M1-5D',	'M1-6D',
        ]
        if(!uniqueValues.includes(zonedistValue)){
          if (zonedistValue.includes('/')) { 
            this.newHeaders.push(`${zonedistValue}`)
  
            this.columnsToCopy.push('M/R');  // Agregar 'M/R' si ZONEDIST contiene '/'
          } else {
            const zonedistPrefix = zonedistValue.slice(0, 2).toUpperCase();  // Obtener prefijo de ZONEDIST
            if (this.headers.includes(zonedistPrefix)){
              this.newHeaders.push(`${zonedistValue}`)
  
              this.columnsToCopy.push(zonedistPrefix);  
            }else{
              const zonedistPrefix = zonedistValue.slice(0, 3).toUpperCase();  // Obtener prefijo de ZONEDIST
              if (this.headers.includes(zonedistPrefix)){
                this.newHeaders.push(`${zonedistValue}`)
                this.columnsToCopy.push(zonedistPrefix);  
              }
            }
          }
        }else{
          this.newHeaders.push(`${zonedistValue}`)
          this.columnsToCopy.push(zonedistValue)
        }
        
      }
    });

    // Actualizar el CSV después de aplicar la lógica
    this.updateCsvData();
  }
  
  updateCsvData(): void {
    const newHeaders = this.columnsToCopy; 
    const newCsv: string[][] = [newHeaders];  

    // Recorrer cada fila del CSV original y copiar las columnas seleccionadas
    this.csvData.slice(1).forEach(row => {
      const newRow = this.columnsToCopy.map(columnName => {
        const columnIndex = this.headers.indexOf(columnName);
        return columnIndex !== -1 ? row[columnIndex] : ''; 
      });
      newCsv.push(newRow);
    });
    this.newHeaders.unshift('ID', 'Use')
    this.newCsvData = newCsv;  
  }
  


  downloadExcelFromMultipleTables(): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Datos Exportados');
  

    const findRowIndexBasedOnColumnValueInCustomTable = (table: HTMLTableElement, colIndex: number, searchValue: string) => {
      const rowIndexes: number[] = []; // Para almacenar los índices de las filas que coinciden
      
      // Obtener todas las filas de la tabla
      const rows = Array.from(table.rows);
      
      // Iterar sobre las filas de la tabla
      rows.forEach((row, rowIndex) => {
        const cell = row.cells[colIndex]; // Obtener la celda en la columna específica
        
        if (cell) {
          const cellValue = cell.textContent?.trim(); // Obtener el texto de la celda
    
          // Si el valor de la celda coincide con el valor buscado, almacenamos el índice de la fila
          if (cellValue === searchValue) {
            rowIndexes.push(rowIndex); // Almacenar el rowIndex
          }
        }
      });
    
      return rowIndexes; // Devolver todos los índices de filas donde se encontró el valor
    };
    
       // convertir una tabla HTML en datos Excel
    const addTableToWorksheet = (
      table: HTMLTableElement,
      worksheet: ExcelJS.Worksheet,
      excludeIdColumn = false
    ) => {
      const rows = Array.from(table.rows);
      

        rows.forEach((row, rowIndex) => {
        const cells = Array.from(row.cells);
        const rowData = cells.map((cell, cellIndex) => {
          // Excluir la columna 'ID' antes de agregarla
          if (cellIndex === 0 && !excludeIdColumn) {
            return null; // Excluir la columna 'ID'
          }
  
          const link = cell.querySelector('a');
          if (link) {
            return {
              text: link.innerText.trim(),
              hyperlink: link.href,
            }; // Celda con enlace
          }
          return cell.textContent?.trim() || ''; // Texto normal
        }).filter(cell => cell !== null); // Filtrar columnas excluidas
        if (excludeIdColumn) {
          // console.log('pasamos por aqui')
          rowData.unshift(''); // Agregar una celda vacía al inicio de la fila
        }
        const excelRow = worksheet.addRow(rowData);
  
        // Aplicar estilos a las celdas con enlaces
        excelRow.eachCell((cell, colIndex) => {
          const cellValue = cell.value;
  
          // Verificar que cellValue no sea null y que sea un objeto con una propiedad 'hyperlink'
          if (cellValue && typeof cellValue === 'object' && 'hyperlink' in cellValue) {
            const hyperlinkValue = cellValue as { text: string; hyperlink: string };
            cell.font = { color: { argb: '0000FF' }, underline: true }; // Estilo de enlace
            cell.value = { text: hyperlinkValue.text, hyperlink: hyperlinkValue.hyperlink }; // Enlace
          }
        });
        
      });
      
    };
    let st :any;
    let t:any;
    // Agregar tablas al Excel
    const legendTables = document.querySelectorAll('.legend-container table') as NodeListOf<HTMLTableElement>;


    legendTables.forEach((legendTable, index) => {
      // console.log(index)
      if(index === 0 || index === 1){
      addTableToWorksheet(legendTable, worksheet, true);
        if(index === 1){
          worksheet.addRow([]); // Fila en blanco entre tablas
        }
      }else{
      addTableToWorksheet(legendTable, worksheet);

      }
      
    });
  
    const customTable = document.querySelector('.my-custom-table') as HTMLTableElement;
    st = findRowIndexBasedOnColumnValueInCustomTable(customTable,0,'st')
    t = findRowIndexBasedOnColumnValueInCustomTable(customTable,0,'t')
    // console.log('aqui',st);
    // Eliminar la columna 'ID' y agregar al worksheet
    addTableToWorksheet(customTable, worksheet);
  
    // Ajustar el ancho de las columnas
    worksheet.columns.forEach((column) => {
      let maxLength = 10; // Ancho mínimo
      worksheet.columns.forEach((column) => {
        if (column && typeof column.eachCell === 'function') { // Aseguramos que column existe y tiene eachCell
          let maxLength = 10; // Ancho mínimo
          column.eachCell({ includeEmpty: true }, (cell) => {
            if (cell?.value) { // Verificamos que cell y cell.value no sean undefined o null
              const cellLength = cell.value.toString().length;
              if (cellLength > maxLength) {
                maxLength = cellLength;
              }
            }
          });
          column.width = maxLength + 2; // Ajustamos el ancho con un margen
        }
      });
      column.width = maxLength + 2; // Ajustamos el ancho con un margen
    });
  
    


        // console.log(stRows)
        worksheet.eachRow((row, rowIndex) => {
          // Cambiar las condiciones según las filas a las que deseas aplicar el estilo
          // console.log(rowIndex)
          let stRows = st.map((element:any )=>element+4)
          let tRows = t.map((element:any) => element+4)
          if (stRows.includes(rowIndex)) { 
            row.eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '939295' }, // Celeste
              };
            })
            
            }
            if (tRows.includes(rowIndex)) { 
              row.eachCell((cell) => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'D9D9D9' }, // Celeste
                };
              })
              
            }
        });

            

          const moveFirstTwoRowsLeft = (worksheet: ExcelJS.Worksheet) => {
            // Mover las filas 1 y 2 (índices 1 y 2 en ExcelJS)
            [1, 2].forEach((rowIndex) => {
              const row = worksheet.getRow(rowIndex);
          
              // Recorrer todas las celdas de la fila desde la segunda columna (índice 2)
              for (let colIndex = 2; colIndex <= row.cellCount; colIndex++) {
                const currentCell = row.getCell(colIndex);
                const previousCell = row.getCell(colIndex - 1);
          
                // Mover el valor y estilo de la celda hacia la izquierda
                previousCell.value = currentCell.value;
                previousCell.style = currentCell.style;
          
                // Limpiar la celda actual
                currentCell.value = undefined;
                currentCell.style = {};
              }
          
              // Guardar los cambios en la fila
              row.commit();
            });
          };
            
          // move to left
          moveFirstTwoRowsLeft(worksheet);
          worksheet.getCell('A1').value = worksheet.getCell('A1').value +'   '+worksheet.getCell('B1').value +'   '+worksheet.getCell('C1').value + '   ' +worksheet.getCell('D1').value + '\n'+ worksheet.getCell('A2').value + '   '+worksheet.getCell('B2').value +'   '+worksheet.getCell('C2').value    
          worksheet.getCell('B1').value = '' 
          worksheet.getCell('C1').value = '' 
          worksheet.getCell('D1').value = '' 
          worksheet.getCell('A2').value = '' 
          worksheet.getCell('B2').value = '' 
          worksheet.getCell('C2').value = '' 
          worksheet.mergeCells('A1')
          worksheet.getCell('A1').alignment = {
            horizontal: 'center',   // Centra horizontalmente
            vertical: 'middle',     // Centra verticalmente
            wrapText: true          // Hace que el texto se ajuste dentro de la celda
          };

        worksheet.spliceRows(2, 1); //delete row 2
        worksheet.spliceRows(2, 1); 
        worksheet.views = [
          { state: 'frozen', ySplit: 2 } // ySplit: 2 fija las primeras 2 filas
        ];
      // Descargar el archivo Excel
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), `${this.bbl}.xlsx`);
      });
  }
  
  
}
