<section
  class="manage_layers"
  [ngClass]="{
    'manage_layers--fixed_ajusted':
      !adjustElement && twoContainer && !secondRowActive,
    'manage_layers--addressSearchNotVisible':
      !addressSearchIsVisible && !secondRowActive,
    'manage_layers--second_row': !!secondRowActive,
    'manage_layers--only_element': !twoContainer && addressSearchIsVisible
  }"
>
  <div class="header_info d-flex justify-content-between">
    <p class="header_text">Manage Layers</p>
    <span class="icon-left-arrow icon" (click)="close()"></span>
  </div>
  <section class="body_cnt">
    <ul class="tabs">
      <li
        class="tabs-options"
        [ngClass]="{ 'options--active': tab.active }"
        (click)="handleTab(i)"
        *ngFor="let tab of infoTypeData; let i = index"
      >
        {{ tab.type }}
      </li>
    </ul>
    <section class="principal">
      <section class="principal_cnt" *ngIf="generalSelected">
        <section
          class="principal_check"
          *ngFor="let item of manageLayersList; let i = index"
        >
          <section>
            <h1 class="title" *ngIf="!!item.title">{{ item.title }}</h1>
            <div class="input_cnt">
              <app-switch
                class="switch_item"
                [params]="item.switch"
                (onclick)="switchToggle(i, $event.target.checked)"
              ></app-switch>
              <div class="col-md-9">
                <p
                  class="item_text"
                  [ngClass]="{ 'item_text--enabled': item.switch.enabled }"
                >
                  {{ item.label }}
                  <i
                    class="icon-warning icon_warning"
                    *ngIf="false"
                    ngbTooltip="Tooltip test"
                    placement="left"
                  ></i>
                </p>
              </div>
              <div
                class="input_cnt-color_box"
                [ngStyle]="{
                  background: item.color,
                  border: '3px solid' + item.border
                }"
                *ngIf="
                  (!item.circle && item.color && item.switch.enabled) ||
                  (!item.circle && item.border && item.switch.enabled)
                "
              ></div>

              <div
                class="input_cnt-color_circle"
                [ngStyle]="{
                  background: item.color,
                  border: '4px solid' + item.circle
                }"
                *ngIf="item.circle && item.switch.enabled"
              ></div>
              <article class="info_cnt">
                <ng-template #dataAcordingLayer>
                  <div *ngIf="item.label === 'Tax Lot'" style="width: 100%">
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of Finance (DOF) <br />
                    <b>Information Context:</b> A tax lot is a real property
                    recorded on the City Tax Map with a distinct borough, block,
                    and lot number, primarily used for property tax-related
                    identification. A zoning lot is the extent of land to be
                    treated as one lot for the purposes of assessing the Zoning.
                    In most cases, the tax lot and zoning lot are the same, but
                    a zoning lot can be made up of multiple tax lots.
                  </div>

                  <div
                    *ngIf="item.label === 'Zoning District'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Zoning districts are areas of
                    the city that have specific regulations on the size, use,
                    and location of buildings. There are three basic zoning
                    districts: Residential (R), Commercial (C), and
                    Manufacturing (M).
                  </div>

                  <div
                    *ngIf="item.label === 'Commercial Overlay'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> A commercial overlay is a
                    commercial zoning district mapped in residential zoning
                    districts. It allows for commercial buildings to be built in
                    residential zones, and these uses are usually retail,
                    restaurants, bakeries, beauty salons, small offices, etc.
                  </div>

                  <div
                    *ngIf="item.label === 'Special Purpose District'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Special purpose districts
                    encompass regulations that complement and revise the
                    fundamental zoning rules to cater to unique neighborhoods
                    with specific concerns and objectives.
                  </div>

                  <div
                    *ngIf="item.label === 'Special Purpose Subdistrict'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> contains internal subdistricts
                    of any subdivided special purpose districts. Please note
                    that subdistrict data might not be completely digitized and
                    may be incomplete.
                  </div>

                  <div *ngIf="item.label === 'MIH'" style="width: 100%">
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> In 2016, the city adopted
                    Mandatory Inclusionary Housing (MIH), which has since been
                    applied in areas where zoning changes significantly increase
                    permitted residential density. Within an MIH area, new
                    developments, enlargements and conversions that create more
                    than 10 units or 12,500 square feet of residential floor
                    area on a zoning lot must comply with one of the options
                    that are available there. See
                    <a
                      style="
                        line-height: 1;
                        font-weight: 200;
                        text-decoration: none;
                        color: #f0d459;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                      "
                      href="https://zr.planning.nyc.gov/article-ii/chapter-3#23-90"
                      target="_blank"
                      >ZR 23-90</a
                    >
                    and
                    <a
                      href="https://zr.planning.nyc.gov/appendix-f-inclusionary-housing-designated-areas-and-mandatory-inclusionary-housing-areas"
                      target="_blank"
                      style="
                        line-height: 1;
                        font-weight: 200;
                        text-decoration: none;
                        color: #f0d459;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                      "
                      >Appendix F
                    </a>
                    for further information.
                  </div>
                  <div *ngIf="item.label === 'IHDA'" style="width: 100%">
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> The Inclusionary Housing
                    Designated Areas (IHDA) Program was created in 2005 to
                    encourage the creation and preservation of affordable
                    housing in medium and high-density neighborhoods being
                    rezoned to create new housing opportunities. The program
                    modifies the base floor area ratio (FAR) available for
                    developments and offers a floor area bonus for developments
                    that provide affordable housing either on-site or within the
                    surrounding community. All mapped IHDAs are included in
                    Appendix F of the Zoning Resolution. See
                    <a
                      style="
                        line-height: 1;
                        font-weight: 200;
                        text-decoration: none;
                        color: #f0d459;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                      "
                      href="https://zr.planning.nyc.gov/article-ii/chapter-3#23-90"
                      target="_blank"
                      >ZR 23-90</a
                    >
                    and
                    <a
                      href="https://zr.planning.nyc.gov/appendix-f-inclusionary-housing-designated-areas-and-mandatory-inclusionary-housing-areas"
                      target="_blank"
                      style="
                        line-height: 1;
                        font-weight: 200;
                        text-decoration: none;
                        color: #f0d459;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                      "
                      >Appendix F
                    </a>
                    for further information
                  </div>

                  <div
                    *ngIf="item.label === 'Limited Height Districts'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Limited Height District (LH) is
                    a district where building heights are limited. This applies
                    to buildings and other structures and is set by the
                    Landmarks Preservation Commission.
                  </div>

                  <div
                    *ngIf="item.label === 'Historic District'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Historic districts are areas
                    that have been designated by the Landmarks Preservation
                    Commission (LPC) as having special architectural,
                    historical, or cultural significance. These districts are
                    protected by law and are subject to special zoning
                    regulations that help preserve their unique character and
                    charm.
                  </div>

                  <div
                    *ngIf="item.label === 'Landmark Site'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Landmark Sites have special
                    historical, cultural, or aesthetic value to the city, state
                    or nation, and is an important part of the City's heritage.
                    These are established by the Landmarks Preservation
                    Commission (LPC). This layer contains site boundaries for
                    all designated individual landmarks. <br />
                    Alterations, demolition, and new construction are
                    permissible, subject to LPC's evaluation of the proposed
                    alterations to ensure their appropriateness.
                  </div>

                  <div
                    *ngIf="item.label === 'Effective Flood Maps 2007'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> Flood Map Service Center <br />
                    <b>Agency:</b> Federal Emergency Management Agency (FEMA)
                    <br />
                    <b>Information Context:</b> Digital data used for creating
                    new Flood Insurance Rate Maps. The 2007 Effective FIRMs are
                    used to determine flood insurance rates in New York City
                    until the new flood maps are adopted.
                  </div>

                  <div
                    *ngIf="item.label === 'Preliminary Flood Maps 2015'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> Flood Map Service Center <br />
                    <b>Agency:</b> Federal Emergency Management Agency (FEMA)
                    <br />
                    <b>Information Context:</b> Released in 2015 as part of a
                    citywide flood map update, are the best available flood
                    hazard data for building code and planning purposes. They
                    show areas vulnerable to flooding from 1% annual chance
                    storm, also sometimes called the Special Flood Hazard Area
                    or 100-year floodplain.
                  </div>

                  <div
                    *ngIf="item.label === 'Environmental Designations'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> This data set contains
                    information and locations of Environmental (E) Designation
                    sites. E-designation sites are properties that have
                    environmental requirements relating to air, noise or
                    hazardous materials that must be investigated and addressed
                    before an owner can obtain a building permit for the
                    property’s redevelopment.
                  </div>

                  <div
                    *ngIf="item.label === 'Subway Lines'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Metropolitan Transportation Authority (MTA)
                    <br />
                    <b>Information Context:</b> City subway lines and stations.
                  </div>

                  <div *ngIf="item.label === 'Topography'" style="width: 100%">
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Office of Technology and Innovation (OTI)
                    <br />
                    <b>Information Context:</b> Planimetric basemap layer
                    containing citywide 2-ft contour line features.
                  </div>

                  <div
                    *ngIf="item.label === 'Community District'"
                    style="width: 100%"
                  >
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Boundaries of Community
                    Districts.
                  </div>

                  <div *ngIf="item.label === 'Boroughs'" style="width: 100%">
                    <b>Data Source:</b> NYC Open Data <br />
                    <b>Agency:</b> Department of City Planning (DCP) <br />
                    <b>Information Context:</b> Boundaries of Boroughs (water
                    areas excluded).
                  </div>
                </ng-template>
                <i
                  class="icon-info icon"
                  [tooltipClass]="
                    item.label === 'IHDA'
                      ? 'manage_layer_data_acording_layer--ihda'
                      : ' manage_layer_data_acording_layer'
                  "
                  [ngbTooltip]="dataAcordingLayer"
                  placement="right"
                  container="body"
                  [closeDelay]="
                    item.label === 'MIH' || item.label === 'IHDA' ? 2000 : 0
                  "
                ></i>
              </article>
            </div>
            <section
              class="check_cnt"
              [ngStyle]="{
                '--percentage': !!item.switch.enabled ? item.percentage : 0
              }"
              [ngClass]="{ 'check_cnt--visible': !!item.switch?.enabled }"
            >
              <ul class="secondary_check">
                <li
                  class="secondary_check-item"
                  *ngFor="let opt of item.optionsList; let x = index"
                >
                  <span class="checkbox">
                    <div class="d-flex justify-content-between">
                      <app-checkbox
                        [id]="'checkbox-' + opt.optionName + '-' + x"
                        [disabled]="!item.switch?.enabled"
                        [checked]="!!opt?.enabled"
                        (onclick)="
                          handleOptionsCheckbox(null, $event, x, false)
                        "
                      ></app-checkbox>
                      <label class="checkbox-label">{{ opt.label }}</label>
                    </div>
                    <div
                      class="checkbox-color_box"
                      [ngStyle]="{
                        background: opt.color,
                        border: '3px solid' + opt.border
                      }"
                    ></div>
                  </span>
                  <ul
                    class="color_cnt"
                    [ngClass]="{
                      'color_cnt--enabled':
                        !!useColors && opt.colorList !== null
                    }"
                  >
                    <li
                      class="color_cnt-item"
                      *ngFor="let ele of opt.colorList; let y = index"
                    >
                      <div
                        class="color_box"
                        [ngStyle]="{
                          background: ele.color,
                          border: '3px solid' + ele.border
                        }"
                      ></div>
                      <label class="label">{{ ele.label }}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </section>
        </section>
      </section>

      <section class="principal_cnt" *ngIf="airRightsSelected">
        <p style="color: white">Coming soon</p>
        <section style="display: none;" 
          class="principal_check"
          *ngFor="let item of airRightsList; let i = index"
        >
          <section>
            <div class="input_cnt">
              <app-switch
                class="switch_item"
                [params]="item.switch"
                (onclick)="switchToggleAirHeight(i, $event.target.checked)"
              ></app-switch>
              <div class="col-md-9">
                <p
                  class="item_text"
                  [ngClass]="{ 'item_text--enabled': item.switch.enabled }"
                >
                  {{ item.label }}
                  <i
                    class="icon-warning icon_warning"
                    *ngIf="false"
                    ngbTooltip="Tooltip test"
                    placement="right"
                    container="body"
                    tooltipClass="manage_layer_tooltip"
                  ></i>
                </p>
              </div>
              <div
                class="input_cnt-color_box"
                [ngStyle]="{
                  background: item.color,
                  border: '3px solid' + item.border
                }"
                *ngIf="
                  (!item.circle && item.color && item.switch.enabled) ||
                  (!item.circle && item.border && item.switch.enabled)
                "
              ></div>
              <article class="info_cnt">
                <i
                  class="icon-info icon"
                  ngbTooltip="{{ item.tooltipInfo }}"
                  placement="right"
                  container="body"
                  tooltipClass="manage_layer_tooltip"
                ></i>
              </article>
            </div>
            <section
              class="check_cnt"
              [ngStyle]="{
                '--percentage': !!item.switch.enabled ? item.percentage : 0
              }"
              [ngClass]="{ 'check_cnt--visible': !!item.switch?.enabled }"
            >
              <ul class="secondary_check">
                <li
                  class="secondary_check-item"
                  *ngFor="let opt of item.optionsList; let x = index"
                >
                  <span class="checkbox">
                    <div class="d-flex justify-content-between">
                      <app-checkbox
                        [id]="'checkbox-' + opt.optionName + '-' + x"
                        [disabled]="!item.switch?.enabled"
                        [checked]="!!opt?.enabled"
                        (onclick)="
                          handleOptionsCheckbox(null, $event, x, false)
                        "
                      ></app-checkbox>
                      <label class="checkbox-label">{{ opt.label }}</label>
                    </div>
                    <div
                      class="checkbox-color_box"
                      [ngStyle]="{
                        background: opt.color,
                        border: '3px solid' + opt.border
                      }"
                    ></div>
                  </span>
                  <ul
                    class="color_cnt"
                    [ngClass]="{
                      'color_cnt--enabled':
                        !!useColors && opt.colorList !== null
                    }"
                  >
                    <li
                      class="color_cnt-item"
                      *ngFor="let ele of opt.colorList; let y = index"
                    >
                      <div
                        class="color_box"
                        [ngStyle]="{
                          background: ele.color,
                          border: '3px solid' + ele.border
                        }"
                      ></div>
                      <label class="label">{{ ele.label }}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </section>
        </section>
      </section>

      <section class="principal_cnt" *ngIf="statisticsSelected">
        <p style="color: white">Coming soon</p>
      </section>
    </section>
  </section>
</section>
