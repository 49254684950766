import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ZoningAnalysisService } from 'src/app/services/zoning-analysis.service';
import { Constants } from 'src/app/shared';
import { AccordionTO } from 'src/app/ui/accordion/accordion.to';
import { InputTypes } from 'src/app/ui/input/input-types.enum';
import { InputTO } from 'src/app/ui/input/input.to';
import { SwitchTO } from 'src/app/ui/switch/switch.to';
import { NavBarCloseService } from '../../../services/nav-bar-close.service';
import { SetTagEnvelopeService } from 'src/app/services/set_tag_envelope.service';
import { EMPTY, Subscription, tap } from 'rxjs';
import { SelectUseService } from 'src/app/services/select-use-info.service';
import { DisabledElementsExtrude } from 'src/app/services/disabled-elements-extrude.service';
import { useBulk } from 'use-bulk';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-zoning-analysis',
  templateUrl: './zoning-analysis.component.html',
  styleUrls: ['./zoning-analysis.component.scss'],
})
export class ZoningAnalysisComponent implements OnInit, OnDestroy {
  /**
   * form group
   */
  form!: FormGroup;

  /**
   * list of options of zoning analysis data
   */
  layersList: any[] = [];
  /**
   * list of options of useRegulations
   */
  regulationsOptions: any[] = [];
  /**
   * flag that handles cornerLotPortion
   */
  cornerLotPortion: boolean = true;
  /**
   * flag that handles throughtLotpORTION
   */
  throughLotPortion: boolean = true;
  /**
   * Flag that handles interiorLotPortion
   */
  interiorLotPortion: boolean = true;
  /**
   * Flag that handle frontLotLines
   */
  frontLotLines: boolean = true;
  /**
   * Flag that handle sideLotLines
   */
  sideLotLines: boolean = true;
  /**
   * Flag that handle rearLotLine
   */
  rearLotLine: boolean = true;
  /**
   * Flag that handle frontYard
   */
  frontYard: boolean = true;
  /**
   * Flaf that handle sideYard
   */
  sideYard: boolean = true;
  /**
   * Flag that handle rearYard
   */
  rearYard: boolean = true;
  /**
   * Flag that handle sliverLawCheck
   */
  sliverLawCheck: boolean = true;
  /**
   * Flag that handle visualizedYards
   */
  visualizedYards!: boolean;
  /**
   * Flag that handle inlcusionaryHousingCheck
   */
  inclusionaryHousingCheck!: boolean;
  /**
   * Flag that hadle tagvalue
   */
  tagValue: boolean = true;
  /**
   * Flag that handles bulkSelected
   */
  bulkSelected: boolean = true;
  /**
   * Flag that handles regulationsSelected
   */
  regulationSelected: boolean = false;
  /**
   * Flag that handles layerSelected
   */
  layerSelected: boolean = false;
  /**
   * Flag that handles loadingGif
   */
  loadingGif: boolean = false;
  /**
   * Flag that handles assembleIsActivated
   */
  assembleIsActivated: boolean = false;
  /**
   * Flag that handles enableAssembleBtn
   */
  enableAssembleBtn!: boolean;
  /**
   * Flag that handles tagIsActivated
   */
  tagIsActivated: boolean = true;

  /**
   * Flag that handles when tag btn is enabled
   */
  tagBtnIsEnabled!: boolean;

  /**
   * Flag that handles when first envelope is triggered
   */
  firstEnvelope!: boolean;

  /**
   * Flag that handles the temporal disabled of the assemble btn
   */
  disabledTemporalAssaemble: boolean = true;

  /**
   * Flag that handles the temporal invisible that will be active for a few seconds
   */
  setInvisibleLayer: boolean = false;

  assembleData: any;

  capSevenArray: any;

  /**
   * Input that handle if zoning content is closed by arrow
   */
  @Input() zoningClosedByArrow!: boolean;
  /**
   * Input that handle if address search is visible
   */
  @Input() addressSearchIsVisible: boolean = false;
  /**
   * input that handle adjustElement flag is active
   */
  @Input() adjustElement: boolean = false;
  /**
   * Input that handle if component is in second row position
   */
  @Input() secondRowActive: boolean = false;
  /**
   * Input that handles if twoContainer flag is active
   */
  @Input() twoContainer: boolean = false;
  /**
   * Output that handle if container is opened
   */
  @Output() validateContainersOpened = new EventEmitter<string>();
  /**
   * Output that handle if container is closed
   */
  @Output() closeItem = new EventEmitter<string>();
  /**
   * Output that handle if biggerAccordion flag is active
   */
  @Output() biggerAccordion = new EventEmitter<boolean>();

  /**
   * Accordion config
   */
  mapOptionsZoningAnalysis = {
    title: 'Zoning Analysis',
    showInfo: false,
    noDropdown: false,
    bgWhite: true,
    bigger: true,
  } as AccordionTO;

  tableListData = [];

  /**
   * user input config
   */
  useInput = {
    id: 'useInput',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 50,
    placeholder: 'Use',
  } as InputTO;

  /**
   * Lot coverage input config
   */
  lotCoverageOne = {
    id: 'lotCoverageOne',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Lot Coverage',
  } as InputTO;

  /**
   * Lot coverage input config
   */
  lotCoverageTwo = {
    id: 'lotCoverageTwo',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Lot Coverage',
  } as InputTO;

  /**
   * Lot coverage input config
   */
  lotCoverageThree = {
    id: 'lotCoverageThree',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Lot Coverage',
  } as InputTO;

  /**
   * Lot coverage input config
   */
  lotCoverageFour = {
    id: 'lotCoverageFour',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Lot Regulations',
  } as InputTO;

  /**
   * Lot regulations input config
   */
  lotRegulationsOne = {
    id: 'lotRegulationsOne',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Lot Regulations',
  } as InputTO;

  /**
   * Lot regulations input config
   */
  lotRegulationsTwo = {
    id: 'lotRegulationsTwo',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Lot Regulations',
  } as InputTO;

  /**
   * Lot regulations input config
   */
  yardRegulationsOne = {
    id: 'yardRegulationsOne',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Front yard ',
  } as InputTO;

  /**
   * Search address input config
   */
  yardRegulationsTwo = {
    id: 'yardRegulationsTwo',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Front yard',
  } as InputTO;

  /**
   * Search address input config
   */
  yardRegulationsThree = {
    id: 'yardRegulationsThree',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Front yard',
  } as InputTO;

  /**
   * Setback regulations input config
   */
  setBackRegulationsOne = {
    id: 'setBackRegulationsOne',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Set back regulations',
  } as InputTO;

  /**
   * Setback regulations input config
   */
  setBackRegulationsTwo = {
    id: 'setBackRegulationsTwo',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Set back regulations',
  } as InputTO;

  /**
   * Setback regulations input config
   */
  setBackRegulationsThree = {
    id: 'setBackRegulationsThree',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Set back regulations',
  } as InputTO;

  /**
   * Setback regulations input config
   */
  setBackRegulationsFour = {
    id: 'setBackRegulationsFour',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Set back regulations',
  } as InputTO;

  /**
   * Setback regulations input config
   */
  setBackRegulationsFive = {
    id: 'setBackRegulationsFive',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Set back regulations',
  } as InputTO;

  /**
   * Setback regulations input config
   */
  setBackRegulationsSix = {
    id: 'setBackRegulationsSix',
    type: InputTypes.ALPHANUMERIC,
    label: '',
    maxLength: 100,
    placeholder: 'Set back regulations',
  } as InputTO;

  /**
   * List of tabs to select
   */
  infoTypeData = [
    {
      type: Constants.BULK,
      active: true,
    },
    {
      type: Constants.REGULATIONS,
      active: false,
    },
    {
      type: Constants.LAYERS,
      active: false,
    },
  ];

  /**
   * List of use options first list
   */
  useSelectionList: any[] = [];

  /**
   * List of yards options first list
   */
  yardSelectionList: any[] = [];

  /**
   * First list bar info
   */
  useFirstBar: any[] = [];

  useSecondBar: any[] = [];

  useThirdBar: any[] = [];

  generatedUseRequest: any[] = [];

  /**
   * enableFirstBar
   */
  enableFirstBar: boolean = false;

  /**
   * enableSecondBar
   */
  enableSecondBar: boolean = false;

  /**
   * enableThirdBar
   */
  enableThirdBar: boolean = false;

  /**
   * List of use options second list
   */
  useSecondList: any[] = [];

  /**
   * List of use options third list
   */
  useThirdList: any[] = [];

  /**
   * list that contains bulkList info reseted
   */
  bulksListReseted: any[] = [];

  /**
   * list that contains bulkList info
   */
  bulkList: any[] = [];

  /**
   * List that contains rearYardList info
   */
  rearYardList: any[] = [];

  rearYardListReseted: any[] = [];

  //layers

  /**
   * Switch config of switchWideStreet
   */
  switchWideStreet = {
    id: 'switchWideStreet',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchCorner
   */
  switchCorner = {
    id: 'switchCorner',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchDimensionBlock
   */
  switchDimensionBlock = {
    id: 'switchDimensionBlock',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchTypePortion
   */
  switchTypePortion = {
    id: 'switchTypePortion',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchLotLine
   */
  switchLotLine = {
    id: 'switchLotLine',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchYard
   */
  switchYard = {
    id: 'switchYard',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchSetback
   */
  switchSetback = {
    id: 'switchSetback',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchAdjacent
   */
  switchAdjacent = {
    id: 'switchAdjacent',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchAdjacentR5D
   */
  switchAdjacentR5D = {
    id: 'switchAdjacentR5D',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch config of switchLotDimension
   */
  switchLotDimension = {
    id: 'switchLotDimension',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * value of last index grabbed
   */
  lastIndexGrabbed!: number;

  // accordionActiveIndex: number | null = null;
  accordionActiveIndex: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  accordionMemorizedActiveIndex: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  allOpen: boolean = false;

  /**
   * Value of selectuseInfo
   */
  selectUseInfo!: any;

  useBulkAvailableInfo!: any;

  bulkCombination!: any;

  twentyFiveOrLessActive!: any;

  /**
   * Subscription
   */
  subscription!: Subscription;

  selectUseSelectedConfig!: any;

  useIsSelected!: boolean;

  bulkIsSelected!: boolean;

  hideLoadingSelectUse!: boolean;

  useBulkMethods!: any;

  bulksConfigData!: any;

  yardsConfigData!: any;

  rowConfigData!: any;

  useArrayValue: any[] = [];

  listOfEnabledElements: any[] = [];

  finalRequestAssembler: any;
  lastBulkRowIdentifier!: string;
  lessThanBar!: any;
  moreThanBar!: any;
  higherThanBar!: any;

  qhDisabled = 'assets/images/QH1.png';
  qhEnabled = 'assets/images/QH2.png';
  qhActive = 'assets/images/QH3.png';

  bhDisabled = 'assets/images/BH1.png';
  bhEnabled = 'assets/images/BH2.png';
  bhActive = 'assets/images/BH3.png';

  ahDisabled = 'assets/images/AH1.png';
  ahEnabled = 'assets/images/AH2.png';
  ahActive = 'assets/images/AH3.png';

  qgfDisabled = 'assets/images/QGF1.png';
  qgfEnabled = 'assets/images/QGF2.png';
  qgfActive = 'assets/images/QGF3.png';

  stbhDisabled = 'assets/images/STBH1.png';
  stbhEnabled = 'assets/images/STBH2.png';
  stbhActive = 'assets/images/STBH3png';

  stahDisabled = 'assets/images/STAH1.png';
  stahEnabled = 'assets/images/STAH2.png';
  stahActive = 'assets/images/STAH3.png';

  tbDisabled = 'assets/images/TB1.png';
  tbEnabled = 'assets/images/TB2.png';
  tbActive = 'assets/images/TB3png';

  sdDisabled = 'assets/images/SD1.png';
  sdEnabled = 'assets/images/SD2.png';
  sdActive = 'assets/images/SD3.png';

  /**
   * Constructor of the component
   * @param formBuilder form builder
   * @param zoningAnalysisService zoningAnalysisService handler
   * @param navBarCloseService navBarCloseService handler
   * @param setTagEnvelopeService setTagEnvelopeService hanlder
   * @param selectUseInfoService setTagEnvelopeService handler
   * @param disabledElementsService disabledElementsService hanlder
   */
  constructor(
    private formBuilder: FormBuilder,
    private zoningAnalysisService: ZoningAnalysisService,
    private navBarCloseService: NavBarCloseService,
    private setTagEnvelopeService: SetTagEnvelopeService,
    private selectUseInfoService: SelectUseService,
    private disabledElementsService: DisabledElementsExtrude,
    private sanitizer: DomSanitizer
  ) {
    this.loadImg();
    this.constructLayersList();
    this.subscription = EMPTY.pipe(tap(() => null)).subscribe();
    this.selectUseInfo = sessionStorage.getItem('selectUseZaInfo');
    this.finalRequestAssembler = [];
  }

  ngOnInit(): void {
    this.subscription = this.selectUseInfoService.selectUseData.subscribe(
      (data: any) => {
        const datatest = sessionStorage.getItem(
          'zoningAnalysisOptionsSelectedByBBL'
        );
        const tagValue = sessionStorage.getItem('tagIsActivated');
        const bbl = sessionStorage.getItem('bbl');
        const regulationData: any = localStorage.getItem(`assembleData_${bbl}`);
        this.assembleData = regulationData ? JSON.parse(regulationData) : null;
        if (this.assembleData) {
          this.constructRegulationsList();
        }
        if (tagValue === 'true') {
          this.tagIsActivated = true;
        } 
        else if(tagValue === 'false'){
          this.tagIsActivated = false;
        }
        this.selectUseSelectedConfig =
          sessionStorage.getItem('zoningAnalysisOptionsSelectedByBBL') || [];
        this.selectUseInfo = sessionStorage.getItem('selectUseZaInfo');
        const firstEnvelopeValue = sessionStorage.getItem('firstEnvelope');
        if (data === true || data?.bbl) {
          this.hideLoadingSelectUse = true;
        }
        this.validateMemoryAssemble();
        this.loadSelectUseData();
        this.constructBulkList();
      }
    );
    this.subscription = this.disabledElementsService.elementData.subscribe(
      (data: any) => {
        if (!!data) {
          this.loadingGif = data.loadingGifState;
        }
      }
    );
    this.form = this.formBuilder.group({
      useInput: [null],
      lotCoverageOne: [null],
      lotCoverageTwo: [null],
      lotCoverageThree: [null],
      lotCoverageFour: [null],
      lotRegulationsOne: [null],
      lotRegulationsTwo: [null],
      yardRegulationsOne: [null],
      yardRegulationsTwo: [null],
      yardRegulationsThree: [null],
      setBackRegulationsOne: [null],
      setBackRegulationsTwo: [null],
      setBackRegulationsThree: [null],
      setBackRegulationsFour: [null],
      setBackRegulationsFive: [null],
      setBackRegulationsSix: [null],

      //layers

      switchLotLine: [],
      switchWideStreet: [],
      switchCorner: [],
      switchDimensionBlock: [],
      switchLotDimension: [],
      switchAdjacent: [],
      switchAdjacentR5D: [],
      switchTypePortion: [],
      switchYard: [],
      switchSetback: [],
    });
    this.useInput.form = this.form;
    this.lotCoverageOne.form = this.form;
    this.lotCoverageTwo.form = this.form;
    this.lotCoverageThree.form = this.form;
    this.lotCoverageFour.form = this.form;
    this.lotRegulationsOne.form = this.form;
    this.lotRegulationsTwo.form = this.form;
    this.yardRegulationsOne.form = this.form;
    this.yardRegulationsTwo.form = this.form;
    this.yardRegulationsThree.form = this.form;
    this.setBackRegulationsOne.form = this.form;
    this.setBackRegulationsTwo.form = this.form;
    this.setBackRegulationsThree.form = this.form;
    this.setBackRegulationsFour.form = this.form;
    this.setBackRegulationsFive.form = this.form;
    this.setBackRegulationsSix.form = this.form;

    //layers
    this.switchWideStreet.form = this.form;
    this.switchCorner.form = this.form;
    this.switchDimensionBlock.form = this.form;
    this.switchTypePortion.form = this.form;
    this.switchLotLine.form = this.form;
    this.switchYard.form = this.form;
    this.switchSetback.form = this.form;
    this.switchAdjacent.form = this.form;
    this.switchAdjacentR5D.form = this.form;
    this.switchLotDimension.form = this.form;
    this.loadLastTabSelected();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  validateMemoryAssemble() {
    const value = this.useIsSelected;

    switch (value) {
      case undefined:
        sessionStorage.setItem('assembleIsActive', 'false');

        break;
      case true:
        sessionStorage.setItem('assembleIsActive', 'false');

        break;
      case false:
        sessionStorage.setItem('assembleIsActive', 'true');

        break;
      default:
        break;
    }
  }

  /**
   * Method that load Selected use data
   */
  loadSelectUseData() {
    this.finalRequestAssembler = [];
    this.listOfEnabledElements = [];
    this.enableAssembleBtn = false;
    this.assembleIsActivated = false;
    const bblNumber = sessionStorage.getItem('bbl');
    const btnTagValue = sessionStorage.getItem('tagIsActivated');
    const firstEnvelopeValue = sessionStorage.getItem('firstEnvelope');
    firstEnvelopeValue === 'true'
      ? (this.firstEnvelope = true)
      : (this.firstEnvelope = false);
    let dataAccordingBBL: any;
    let valueSelectUseSelectedConfig: any = this.selectUseSelectedConfig;
    if (this.selectUseSelectedConfig.length > 0) {
      dataAccordingBBL = valueSelectUseSelectedConfig.includes('"')
        ? JSON.parse(valueSelectUseSelectedConfig)?.find(
            (ele: any) => ele.bbl === bblNumber
          ) || []
        : valueSelectUseSelectedConfig?.find(
            (ele: any) => ele.bbl === bblNumber
          ) || [];
    }

    if (
      (this.selectUseSelectedConfig?.length > 0 &&
        dataAccordingBBL.bbl === bblNumber) ||
      (this.selectUseSelectedConfig?.length > 0 && dataAccordingBBL.length > 0)
    ) {
      this.useSelectionList = dataAccordingBBL.useSelectionList;
      this.useSecondList = dataAccordingBBL.useSecondList;
      this.useThirdList = dataAccordingBBL.useThirdList;
      this.assembleIsActivated = dataAccordingBBL.btnAssemble;
      if (this.assembleIsActivated === true) {
        this.hideLoadingSelectUse = false;
      }
      this.tagIsActivated = btnTagValue === 'true' ? true : false;
      this.tagBtnIsEnabled = dataAccordingBBL.btnTagsEnabled;
      this.useIsSelected = dataAccordingBBL.useIsSelected;
      this.bulkIsSelected = dataAccordingBBL.bulkIsSelected;
    } else {
      if (firstEnvelopeValue === 'true') {
        this.firstEnvelope === true;
        this.enableAssembleBtn = false;
        this.assembleIsActivated = false;
        this.tagIsActivated = btnTagValue === 'true' ? true : false;
        if (this.enableAssembleBtn && this.assembleIsActivated) {
          this.tagBtnIsEnabled = true;
        }
      }
    }
  }

  loadImg() {
    this.qhDisabled = 'assets/images/QH1.png';
    this.qhEnabled = 'assets/images/QH2.png';
    this.qhActive = 'assets/images/QH3.png';

    this.bhDisabled = 'assets/images/BH1.png';
    this.bhEnabled = 'assets/images/BH2.png';
    this.bhActive = 'assets/images/BH3.png';

    this.ahDisabled = 'assets/images/AH1.png';
    this.ahEnabled = 'assets/images/AH2.png';
    this.ahActive = 'assets/images/AH3.png';

    this.qgfDisabled = 'assets/images/QGF1.png';
    this.qgfEnabled = 'assets/images/QGF2.png';
    this.qgfActive = 'assets/images/QGF3.png';

    this.stbhDisabled = 'assets/images/STBH1.png';
    this.stbhEnabled = 'assets/images/STBH2.png';
    this.stbhActive = 'assets/images/STBH3png';

    this.stahDisabled = 'assets/images/STAH1.png';
    this.stahEnabled = 'assets/images/STAH2.png';
    this.stahActive = 'assets/images/STAH3.png';

    this.tbDisabled = 'assets/images/TB1.png';
    this.tbEnabled = 'assets/images/TB2.png';
    this.tbActive = 'assets/images/TB3png';

    this.sdDisabled = 'assets/images/SD1.png';
    this.sdEnabled = 'assets/images/SD2.png';
    this.sdActive = 'assets/images/SD3.png';
  }

  resetUsesBeforeConstruct() {
    this.useSelectionList = [];
  }

  addStringAfterFirstSlash(inputString: any) {
    const index = inputString.indexOf('/');
    const stringToAdd = '<br>';

    if (index !== -1) {
      return (
        inputString.slice(0, index + 1) +
        stringToAdd +
        inputString.slice(index + 1)
      );
    }
    return inputString;
  }

  /**
   * Method that set the original config from the row.
   */
  setUseRowsOriginalConfig() {
    this.bulkIsSelected = false;
    this.useIsSelected = false;
    console.log('info use bulk test', this.useBulkAvailableInfo);

    if (
      JSON.parse(this.selectUseInfo)?.UG1 ||
      JSON.parse(this.selectUseInfo)?.bbl
    ) {
      this.hideLoadingSelectUse = false;
    }
    this.resetUsesBeforeConstruct();

    if (this.useBulkAvailableInfo && !!this.useBulkAvailableInfo[0]) {
      this.useBulkAvailableInfo.forEach((element: any) => {
        const useSelectionListItem = {
          selectInfo: [
            {
              type: element.ZONEDIST,
            },
            {
              type: element.OVERLAY ? element.OVERLAY : '',
            },
          ],
          selectOptions: [
            {
              text: 'R',
              active: false,
              identifier: 0,
              // enabled: JSON.parse(this.selectUseInfo)?.UG1,
              enabled: element.USES.R.length > 0,
            },
            {
              text: 'CF',
              active: false,
              identifier: 0,
              enabled: element.USES.CF.length > 0,
            },
            {
              text: 'C',
              active: false,
              identifier: 0,
              enabled: element.USES.C.length > 0,
            },
            {
              text: 'M',
              active: false,
              identifier: 0,
              enabled: element.USES.M.length > 0,
            },
          ],
          useBarList: [
            !!element.R25 && !element.R65
              ? {
                  visible: !!element.R25 && !element.R65 ? true : false,
                  disabled: true,
                  rText: 'Estimated R use percentage',
                  values: [
                    {
                      value: '25% or less',
                      selected: false,
                      disabled: false,
                    },
                    {
                      value: 'More than 25%',
                      selected: true,
                      disabled: false,
                    },
                  ],
                }
              : !!element.R25 && !!element.R65
              ? {
                  visible: !!element.R25 && !!element.R65 ? true : false,
                  disabled: true,
                  rText: 'Estimated R use percentage',
                  values: [
                    {
                      value: '25% or less',
                      selected: false,
                      disabled: false,
                    },
                    {
                      value: 'Between',
                      selected: false,
                      disabled: false,
                    },
                    {
                      value: '65% or more',
                      selected: true,
                      disabled: false,
                    },
                  ],
                }
              : {},
          ],
        };
        this.useSelectionList.push(useSelectionListItem);
      });
    } else {
      this.useSelectionList = [];
    }
  }

  /**
   * Method that allows drop element into a drop zone
   * @param ev ev value
   * @param index index value
   */
  allowDrop(ev: any) {
    ev.preventDefault();
  }
  /**
   * Method that handle the event drag of an element
   * @param ev ev value
   * @param index index value
   */
  drag(ev: any, index: number) {
    ev.dataTransfer.setData('text', ev.target.id);
    if (ev.dataTransfer) {
      this.bulkList[0].eles[this.lastIndexGrabbed || 0].active = false;
    }
  }
  /**
   * Method that handle the event drop of an element
   * @param ev ev value
   * @param index index value
   */
  drop(ev: any, index: number) {
    this.enableAssembleBtn = true;
    if (this.bulkList[0].eles[index].type === 'selectable') {
      ev.preventDefault();
      var data = ev.dataTransfer.getData('text');
      ev.target.appendChild(document.getElementById(data));
      this.selectBulkList(index);
    }
  }

  /**
   * Method that construct the bulk list data
   */
  constructBulkList() {
    const bblNumber = sessionStorage.getItem('bbl');
    const existingEvelopedData = sessionStorage.getItem(
      `za_envelope_${bblNumber}`
    );
    let dataAccordingBBL: any;
    if (!!existingEvelopedData) {
      dataAccordingBBL = JSON.parse(existingEvelopedData);
    }
    const getSelectUseAvailableRes = JSON.parse(
      sessionStorage.getItem('getSelectUseAvailableRes')!
    );
    this.useBulkMethods = new useBulk(getSelectUseAvailableRes?.BBL_2);
    this.useBulkAvailableInfo = this.useBulkMethods.getUse();

    if (dataAccordingBBL) {
      this.useSelectionList = dataAccordingBBL.useArrayConfig;
      this.bulkList = dataAccordingBBL.bulkArrayConfig;
      this.rearYardList = dataAccordingBBL.rearYardArrayConfig;
      this.useArrayValue = dataAccordingBBL.useArrayValueConfig;
      this.useBulkAvailableInfo = dataAccordingBBL.useBulkAvailableInfoConfig;
      this.finalRequestAssembler = dataAccordingBBL.finalRequestAssemblerConfig;
      this.visualizedYards = dataAccordingBBL.visualizeYardState;
      this.disabledTemporalAssaemble = true;
      this.enableAssembleBtn = true;
      this.assembleIsActivated = false;
      this.bulkIsSelected = true;
      this.useIsSelected = true;
      this.tagIsActivated = true;
      this.tagBtnIsEnabled = true;
      this.firstEnvelope = true;
      this.loadingGif = false;
    } else {
      this.setUseRowsOriginalConfig();
      this.resetRowsBulkList();
      this.resetRowsYardList();
    }
  }

  /**
   * Method that handle the selection of an elementBulk
   * @param event
   * @param index
   */
  selectElementBulk(event: any, index: number) {
    this.selectBulkList(index);
    // let dragged = null;
    const source = document.getElementById(
      this.bulkList[0].eles[index].dragOpt?.id
    );
    // source?.addEventListener('dragstart', (event) => {
    //   // store a ref. on the dragged elem
    //   dragged = event.target;
    //   this.drag(dragged, index);
    // });
    this.enableAssembleBtn = true;
  }

  /**
   * Method that handle the selectBulkList data
   * @param index index value
   */
  selectBulkList(index: number) {
    if (this.bulkList[0].eles[index].type === 'selectable') {
      this.bulkList[0].eles[index].active = true;
      // this.handleBulkOptionImage(index);
    }
    this.bulkIsSelected = this.bulkList[0].eles[index].active;
    this.disabledTemporalAssaemble = true;
  }

  /**
   * Method that handle the activation of rearList
   * @param index index value
   */
  selectRearList(index: number) {
    this.rearYardList[index].active = true;
  }

  selectBtnBulkOpt(indexBulk: any, indexBtn: any, test: any) {
    console.log('dataaa', test);

    this.bulkList[0].eles[indexBulk].buildingOpt.forEach((ele: any) => {
      ele.selected = false;
    });
    this.selectBulkList(indexBulk);
    this.enableAssembleBtn = true;
    this.bulkList[0].eles[indexBulk].buildingOpt[indexBtn].selected =
      !this.bulkList[0].eles[indexBulk].buildingOpt[indexBtn].selected;
  }

  validateText(txt: string): string {
    let value: any;
    switch (txt) {
      case 'QGF':
        value = 'QH + QGF';
        break;
      case 'ST_BH':
        value = 'ST BH';
        break;

      case 'ST_AH':
        value = 'ST AH';
        break;

      default:
        value = txt;
        break;
    }
    return value;
  }

  selectBtnRearOpt(indexRear: any, indexBtn: any, test: any) {
    console.log('dataaa', test);

    this.rearYardList[indexRear].buildingOpt.forEach((ele: any) => {
      ele.selected = false;
    });
    this.selectRearList(indexRear);
    this.enableAssembleBtn = true;
    this.rearYardList[indexRear].buildingOpt[indexBtn].selected =
      !this.rearYardList[indexRear].buildingOpt[indexBtn].selected;
  }

  hasEmptyType(index: any): boolean {
    if (this.useSelectionList[index].selectInfo[0].type.length <= 5) {
      return this.useSelectionList[index].selectInfo.some(
        (item: any) => item.type === '' && item.type.length < 5
      );
    } else {
      return false;
    }
  }

  updateRowsBulkList(
    indexUseItem: number,
    rowIndex: number,
    bulksListAccordingArray: any
  ) {
    let ovalObjectValue: any;
    let ovalQhInfoArray: any[] = [];
    let ovalBhInfoArray: any[] = [];
    let ovalAhInfoArray: any[] = [];
    let ovalQGFInfoArray: any[] = [];
    let ovalSTBHInfoArray: any[] = [];
    let ovalSTAHInfoArray: any[] = [];
    let ovalTBInfoArray: any[] = [];
    let ovalSDInfoArray: any[] = [];

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].QH,
        secondValue: element?.OVERLAY,
        type: 'qh',
        visible: this.useBulkAvailableInfo[index].BULKS.QH,
      };
      ovalQhInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].BH,
        secondValue: element?.OVERLAY,
        type: 'bh',
        visible: this.useBulkAvailableInfo[index].BULKS.BH,
      };
      ovalBhInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].AH,
        secondValue: element?.OVERLAY,
        type: 'ah',
        visible: this.useBulkAvailableInfo[index].BULKS.AH,
      };
      ovalAhInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].QGF,
        secondValue: element?.OVERLAY,
        type: 'qgf',
        visible: this.useBulkAvailableInfo[index].BULKS.QGF,
      };
      ovalQGFInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].ST_BH,
        secondValue: element?.OVERLAY,
        type: 'stbh',
        visible: this.useBulkAvailableInfo[index].BULKS.ST_BH,
      };
      ovalSTBHInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].ST_AH,
        secondValue: element?.OVERLAY,
        type: 'stah',
        visible: this.useBulkAvailableInfo[index].BULKS.ST_AH,
      };
      ovalSTAHInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].TB,
        secondValue: element?.OVERLAY,
        type: 'tb',
        visible: this.useBulkAvailableInfo[index].BULKS.TB,
      };
      ovalTBInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: bulksListAccordingArray[index].SD,
        secondValue: element?.OVERLAY,
        type: 'sd',
        visible: this.useBulkAvailableInfo[0].SD,
      };
      ovalSDInfoArray.push(ovalObjectValue);
    });

    this.bulksConfigData.forEach((element: any, bulkLitIndex: any) => {
      this.bulkList = this.bulkList = [
        {
          updated: true,
          eles: [
            {
              type:
                !this.bulkCombination.QH ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              active: false,
              image: !this.bulkCombination.QH
                ? this.qhDisabled
                : this.bulkCombination.QH
                ? this.qhEnabled
                : this.bulkCombination.QH &&
                  this.bulkList[0]?.eles[0]?.active === true
                ? this.qhActive
                : this.qhDisabled,
              text: 'QH',
              bbl: element.BBL,
              identifier: 0,
              ovalInfo: ovalQhInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.BH ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination.BH
                ? this.bhDisabled
                : this.bulkCombination.BH
                ? this.bhEnabled
                : this.bulkCombination.BH &&
                  this.bulkList[0]?.eles[1]?.active === true
                ? this.bhActive
                : this.bhDisabled,
              text: 'BH',
              bbl: element.BBL,
              identifier: 1,
              ovalInfo: ovalBhInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.AH ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination
                ? this.ahDisabled
                : this.bulkCombination.AH
                ? this.ahEnabled
                : this.bulkCombination.AH &&
                  this.bulkList[0]?.eles[2]?.active === true
                ? this.ahActive
                : this.ahDisabled,
              text: 'AH',
              bbl: element.BBL,
              identifier: 2,
              ovalInfo: ovalAhInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.QGF ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination.QGF
                ? this.qgfDisabled
                : this.bulkCombination.QGF
                ? this.qgfEnabled
                : this.bulkCombination.QGF &&
                  this.bulkList[0]?.eles[3]?.active === true
                ? this.qgfActive
                : this.qgfDisabled,
              text: 'QGF',
              bbl: element.BBL,
              identifier: 3,
              ovalInfo: ovalQGFInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.ST_BH ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination.ST_BH
                ? this.stbhDisabled
                : this.bulkCombination.ST_BH
                ? this.stbhEnabled
                : this.bulkCombination.ST_BH &&
                  this.bulkList[0]?.eles[4]?.active === true
                ? this.stbhActive
                : this.stbhDisabled,
              text: 'ST_BH',
              bbl: element.BBL,
              identifier: 4,
              ovalInfo: ovalSTBHInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.ST_AH ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination.ST_AH
                ? this.stahDisabled
                : this.bulkCombination.ST_AH
                ? this.stahEnabled
                : this.bulkCombination.ST_AH &&
                  this.bulkList[0]?.eles[5]?.active === true
                ? this.stahActive
                : this.stahDisabled,
              text: 'ST_AH',
              bbl: element.BBL,
              identifier: 5,
              reposition: true,
              ovalInfo: ovalSTAHInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.TB ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination.TB
                ? this.tbDisabled
                : this.bulkCombination.TB
                ? this.tbEnabled
                : this.bulkCombination.TB &&
                  this.bulkList[0]?.eles[6]?.active === true
                ? this.tbActive
                : this.tbDisabled,
              text: 'TB',
              bbl: element.BBL,
              identifier: 6,
              reposition: true,
              ovalInfo: ovalTBInfoArray,
            },
            {
              active: false,
              type:
                !this.bulkCombination.SD ||
                (!this.useSelectionList[rowIndex].selectOptions[0].active &&
                  !this.useSelectionList[rowIndex].selectOptions[1].active &&
                  !this.useSelectionList[rowIndex].selectOptions[2].active &&
                  !this.useSelectionList[rowIndex].selectOptions[3].active)
                  ? 'disabled'
                  : 'selectable',
              image: !this.bulkCombination.SD
                ? this.sdDisabled
                : this.bulkCombination.SD
                ? this.sdEnabled
                : this.bulkCombination.SD &&
                  this.bulkList[0]?.eles[7]?.active === true
                ? this.sdActive
                : this.sdDisabled,
              text: 'SD',
              bbl: element.BBL,
              identifier: 7,
              reposition: true,
              ovalInfo: ovalSDInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image: '',
              text: '',
              identifier: 8,
              reposition: false,
              ovalInfo: [],
            },
          ],
        },
      ];
    });
  }

  resetRowsBulkList() {
    let ovalObjectValue: any;
    let ovalQhInfoArray: any[] = [];
    let ovalBhInfoArray: any[] = [];
    let ovalAhInfoArray: any[] = [];
    let ovalQGFInfoArray: any[] = [];
    let ovalSTBHInfoArray: any[] = [];
    let ovalSTAHInfoArray: any[] = [];
    let ovalTBInfoArray: any[] = [];
    let ovalSDInfoArray: any[] = [];

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'qh',
        visible: this.useBulkAvailableInfo[index].BULKS.QH,
      };
      ovalQhInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'bh',
        visible: this.useBulkAvailableInfo[index].BULKS.BH,
      };
      ovalBhInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'ah',
        visible: this.useBulkAvailableInfo[index].BULKS.AH,
      };
      ovalAhInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'qgf',
        visible: this.useBulkAvailableInfo[index].BULKS.QGF,
      };
      ovalQGFInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'stbh',
        visible: this.useBulkAvailableInfo[index].BULKS.ST_BH,
      };
      ovalSTBHInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'stah',
        visible: this.useBulkAvailableInfo[index].BULKS.ST_AH,
      };
      ovalSTAHInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'tb',
        visible: this.useBulkAvailableInfo[index].BULKS.TB,
      };
      ovalTBInfoArray.push(ovalObjectValue);
    });

    this.useBulkAvailableInfo.forEach((element: any, index: number) => {
      ovalObjectValue = {
        value: element?.ZONEDIST,
        active: false,
        enabled: false,
        secondValue: element?.OVERLAY,
        type: 'sd',
        visible: this.useBulkAvailableInfo[index].BULKS.SD,
      };
      ovalSDInfoArray.push(ovalObjectValue);
    });
    this.bulkList = this.bulksListReseted;
    this.useBulkAvailableInfo.forEach((element: any) => {
      this.bulkList = [
        {
          updated: false,
          eles: [
            {
              type: 'disabled',
              active: false,
              image:
                this.bulkList[0]?.eles[0]?.type === 'disabled'
                  ? 'assets/images/QH1.png'
                  : this.bulkList[0]?.eles[0]?.type === 'selectable'
                  ? 'assets/images/QH2.png'
                  : this.bulkList[0]?.eles[0]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[0]?.active === true
                  ? 'assets/images/QH3.png'
                  : 'assets/images/QH1.png',
              text: 'QH',
              identifier: 0,
              ovalInfo: ovalQhInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[1]?.type === 'disabled'
                  ? 'assets/images/BH1.png'
                  : this.bulkList[0]?.eles[1]?.type === 'selectable'
                  ? 'assets/images/BH2.png'
                  : this.bulkList[0]?.eles[1]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[1]?.active === true
                  ? 'assets/images/BH3.png'
                  : 'assets/images/BH1.png',
              text: 'BH',
              identifier: 1,
              ovalInfo: ovalBhInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[2]?.type === 'disabled'
                  ? 'assets/images/AH1.png'
                  : this.bulkList[0]?.eles[2]?.type === 'selectable'
                  ? 'assets/images/AH2.png'
                  : this.bulkList[0]?.eles[2]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[2]?.active === true
                  ? 'assets/images/AH3.png'
                  : 'assets/images/AH1.png',
              text: 'AH',
              identifier: 2,
              ovalInfo: ovalAhInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[3]?.type === 'disabled'
                  ? 'assets/images/QGF1.png'
                  : this.bulkList[0]?.eles[3]?.type === 'selectable'
                  ? 'assets/images/QGF2.png'
                  : this.bulkList[0]?.eles[3]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[3]?.active === true
                  ? 'assets/images/QGF3.png'
                  : 'assets/images/QGF1.png',
              text: 'QGF',
              identifier: 3,
              ovalInfo: ovalQGFInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[4]?.type === 'disabled'
                  ? 'assets/images/STBH1.png'
                  : this.bulkList[0]?.eles[4]?.type === 'selectable'
                  ? 'assets/images/STBH2.png'
                  : this.bulkList[0]?.eles[4]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[4]?.active === true
                  ? 'assets/images/STBH3.png'
                  : 'assets/images/STBH1.png',
              text: 'ST_BH',
              identifier: 4,
              ovalInfo: ovalSTBHInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[5]?.type === 'disabled'
                  ? 'assets/images/STAH1.png'
                  : this.bulkList[0]?.eles[5]?.type === 'selectable'
                  ? 'assets/images/STAH2.png'
                  : this.bulkList[0]?.eles[5]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[5]?.active === true
                  ? 'assets/images/STAH3.png'
                  : 'assets/images/STAH1.png',
              text: 'ST_AH',
              identifier: 5,
              reposition: true,
              ovalInfo: ovalSTAHInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[6]?.type === 'disabled'
                  ? 'assets/images/TB1.png'
                  : this.bulkList[0]?.eles[6]?.type === 'selectable'
                  ? 'assets/images/TB2.png'
                  : this.bulkList[0]?.eles[6]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[6]?.active === true
                  ? 'assets/images/TB3.png'
                  : 'assets/images/TB1.png',
              text: 'TB',
              identifier: 6,
              reposition: true,
              ovalInfo: ovalTBInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image:
                this.bulkList[0]?.eles[7]?.type === 'disabled'
                  ? 'assets/images/SD1.png'
                  : this.bulkList[0]?.eles[7]?.type === 'selectable'
                  ? 'assets/images/SD2.png'
                  : this.bulkList[0]?.eles[7]?.type === 'selectable' &&
                    this.bulkList[0]?.eles[7]?.active === true
                  ? 'assets/images/SD3.png'
                  : 'assets/images/SD1.png',
              text: 'SD',
              identifier: 7,
              reposition: true,
              ovalInfo: ovalSDInfoArray,
            },
            {
              active: false,
              type: 'disabled',
              image: '',
              text: '',
              identifier: 8,
              reposition: false,
              ovalInfo: [],
            },
          ],
        },
      ];
    });
  }

  updateRowsYardList(opt?: number) {
    this.useBulkAvailableInfo.forEach((element: any) => {
      this.rearYardList = this.rearYardList = [
        {
          updated: false,
          eles: [
            {
              active: this.rearYardList[0]?.eles[0]?.active ? true : false,
              type:
                this.yardsConfigData[0].A === true ? 'selectable' : 'disabled',
              buildingOpt: [
                {
                  btnName: 'A',
                  selected: this.rearYardList[0]?.eles[0]?.buildingOpt[0]
                    .selected
                    ? true
                    : false,
                  firstElement: true,
                },
              ],
              image:
                this.rearYardList[0]?.eles[0]?.type === 'disabled'
                  ? 'assets/images/rear-yard-one-disabled.png'
                  : this.rearYardList[0]?.eles[0]?.type === 'selectable' &&
                    this.rearYardList[0]?.eles[0]?.active === false
                  ? 'assets/images/rear-yard-one-semi.png'
                  : this.rearYardList[0]?.eles[0]?.type === 'selectable' &&
                    this.rearYardList[0]?.eles[0]?.active === true
                  ? 'assets/images/rear-yard-one.png'
                  : 'assets/images/rear-yard-one-disabled.png',
            },
            {
              active: this.rearYardList[0]?.eles[1]?.active ? true : false,
              type:
                this.yardsConfigData[0].B_1 === true ||
                this.yardsConfigData[0].B_2 === true ||
                this.yardsConfigData[0].B_3 === true
                  ? 'selectable'
                  : 'disabled',
              image:
                this.rearYardList[0]?.eles[0]?.type === 'disabled'
                  ? 'assets/images/rear-yard-vertical-disabled.png'
                  : this.rearYardList[0]?.eles[1]?.type === 'selectable' &&
                    !this.rearYardList[0]?.eles[1]?.buildingOpt[0]?.selected &&
                    !this.rearYardList[0]?.eles[1]?.buildingOpt[1]?.selected &&
                    !this.rearYardList[0]?.eles[1]?.buildingOpt[2]?.selected
                  ? 'assets/images/rear-yard-vertical-semi.png'
                  : !!this.rearYardList[0]?.eles[1]?.buildingOpt[0]?.selected
                  ? 'assets/images/rear-yard-vertical.png'
                  : !!this.rearYardList[0]?.eles[1]?.buildingOpt[1]?.selected
                  ? 'assets/images/rear-yard-vertical-typeb.png'
                  : !!this.rearYardList[0]?.eles[1]?.buildingOpt[2]?.selected
                  ? 'assets/images/rear-yard-vertical-typec.png'
                  : 'assets/images/rear-yard-vertical-disabled.png',
              buildingOpt: [
                {
                  btnName: 'B1',
                  selected: !!this.rearYardList[0].eles[1].buildingOpt[0]
                    ?.selected
                    ? true
                    : false,
                  visible: this.yardsConfigData[0].B_1,
                  firstElement: true,
                },
                {
                  btnName: 'B2',
                  selected: !!this.rearYardList[0].eles[1].buildingOpt[1]
                    ?.selected
                    ? true
                    : false,
                  visible: this.yardsConfigData[0].B_2,
                  firstElement: false,
                },
                {
                  btnName: 'B3',
                  selected: !!this.rearYardList[0].eles[1].buildingOpt[2]
                    ?.selected
                    ? true
                    : false,
                  visible: this.yardsConfigData[0].B_3,
                  firstElement: false,
                },
              ],
            },
            {
              active: this.rearYardList[0]?.eles[2]?.active ? true : false,
              type:
                this.yardsConfigData[0].C === true ? 'selectable' : 'disabled',
              buildingOpt: [
                {
                  btnName: 'C',
                  selected: this.rearYardList[0]?.eles[2]?.buildingOpt[0]
                    .selected
                    ? true
                    : false,
                  firstElement: true,
                },
              ],
              image:
                this.rearYardList[0]?.eles[2]?.type === 'disabled'
                  ? 'assets/images/rear-yard-two-disabled.png'
                  : this.rearYardList[0]?.eles[2]?.type === 'selectable' &&
                    this.rearYardList[0]?.eles[2]?.active === false
                  ? 'assets/images/rear-yard-two-semi.png'
                  : this.rearYardList[0]?.eles[2]?.type === 'selectable' &&
                    this.rearYardList[0]?.eles[2]?.active === true
                  ? 'assets/images/rear-yard-two.png'
                  : 'assets/images/rear-yard-two-disabled.png',
            },
          ],
        },
      ];
    });
  }

  resetRowsYardList() {
    this.visualizedYards = true;
    this.useBulkAvailableInfo.forEach((element: any) => {
      this.rearYardList = this.rearYardListReseted;
      this.rearYardList = this.rearYardList = [
        {
          updated: false,
          eles: [
            {
              active: false,
              type: 'disabled',
              image: 'assets/images/rear-yard-one-disabled.png',
              buildingOpt: [
                {
                  btnName: 'A',
                  selected: false,
                  firstElement: true,
                },
              ],
            },
            {
              active: false,
              type: 'disabled',
              image: 'assets/images/rear-yard-vertical-disabled.png',
              buildingOpt: [
                {
                  btnName: 'B1',
                  selected: false,
                  firstElement: true,
                },
                {
                  btnName: 'B2',
                  selected: false,
                },
                {
                  btnName: 'B3',
                  selected: false,
                },
              ],
            },
            {
              active: false,
              type: 'disabled',
              image: 'assets/images/rear-yard-two-disabled.png',
              buildingOpt: [
                {
                  btnName: 'C',
                  selected: false,
                  firstElement: true,
                },
              ],
            },
          ],
        },
      ];
    });
  }

  selectYardRow(index: number, opt: number,event:any,state:any) {
    event.stopPropagation();
    event.preventDefault();

    if(state == 'disabled') return

    this.rearYardList[0]?.eles.forEach((element: any) => {
      element.active = true;
      element.buildingOpt.forEach((item: any) => {
        item.selected = false;
      });
    });

    if (this.useIsSelected && this.bulkIsSelected) {
      this.loadingGif = false;
      this.disabledTemporalAssaemble = true;
      this.useIsSelected = true;
      this.bulkIsSelected = true;
    }

    switch (index) {
      case 0:
        this.rearYardList[0].eles[0].active = true;
        this.rearYardList[0].eles[0].buildingOpt[0].selected = true;

        this.rearYardList[0].eles[1].active = false;
        this.rearYardList[0].eles[1].buildingOpt[0].selected = false;
        this.rearYardList[0].eles[1].buildingOpt[1].selected = false;
        this.rearYardList[0].eles[1].buildingOpt[2].selected = false;

        this.rearYardList[0].eles[2].active = false;
        this.rearYardList[0].eles[2].buildingOpt[0].selected = false;
        break;

      case 1:
        this.rearYardList[0].eles[0].active = false;
        this.rearYardList[0].eles[0].buildingOpt[0].selected = false;

        this.rearYardList[0].eles[1].active = true;
        this.rearYardList[0].eles[1].buildingOpt.forEach((element: any) => {
          element.selected = false;
        });
        this.rearYardList[0].eles[1].buildingOpt[opt].selected = true;

        this.rearYardList[0].eles[2].active = false;
        this.rearYardList[0].eles[2].buildingOpt[0].selected = false;
        break;

      case 2:
        this.rearYardList[0].eles[0].active = false;
        this.rearYardList[0].eles[0].buildingOpt[0].selected = false;

        this.rearYardList[0].eles[1].active = false;
        this.rearYardList[0].eles[1].buildingOpt[0].selected = false;
        this.rearYardList[0].eles[1].buildingOpt[1].selected = false;
        this.rearYardList[0].eles[1].buildingOpt[2].selected = false;

        this.rearYardList[0].eles[2].active = true;
        this.rearYardList[0].eles[2].buildingOpt[0].selected = true;
        break;

      default:
        break;
    }
    this.updateRowsYardList(opt);
    if (!this.rearYardList[0].eles[index].active) {
      this.enableAssembleBtn = false;
      this.disabledTemporalAssaemble = false;
      this.assembleIsActivated = false;
    } else {
      if (
        this.bulkList[0].eles[index].ovalInfo.length ===
        this.listOfEnabledElements.length
      ) {
        this.enableAssembleBtn = true;
        this.disabledTemporalAssaemble = true;
        this.assembleIsActivated = true;
        this.useIsSelected = true;
        this.bulkIsSelected = true;
      }
    }
    this.buildEnvelopeRequest();
  }

  disableElementWithOvalInactive(bulkList: any, index?: any) {
    let array: any[] = [];
    const deselectedImg = this.bulkList[0].eles[index].image.replace('3', '2');
    bulkList.ovalInfo.forEach((element: any) => {
      array.push(element.active);
      if (!array.includes(true)) {
        this.bulkList[0].eles[index].active = false;
      } else {
        this.bulkList[0].eles[index].active = true;
      }
    });
    if (!array.includes(true)) {
      this.bulkList[0].eles[index].image = deselectedImg;
    }
  }

  activateBulkOval(event: any, index: any, indexBulk: any , enabled:boolean) {
    event.stopPropagation();
    event.preventDefault();
    
    if(!enabled) return;

    this.bulkList[0].eles.forEach((item: any) => {
      if (item.ovalInfo[index]) {
        item.ovalInfo[index].active = false;
      }
    });
    this.bulkList[0].eles[indexBulk].ovalInfo[index].active = true;

    switch (this.bulkList[0].eles[indexBulk].ovalInfo[index].type) {
      case 'qh':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/QH3.png';
        this.bulkList[0].eles[0].active = true;
        break;
      case 'bh':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/BH3.png';
        this.bulkList[0].eles[1].active = true;
        break;
      case 'ah':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/AH3.png';
        this.bulkList[0].eles[2].active = true;

        break;
      case 'qgf':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/QGF3.png';
        this.bulkList[0].eles[3].active = true;

        break;
      case 'stbh':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/STBH3.png';
        this.bulkList[0].eles[4].active = true;

        break;
      case 'stah':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/STAH3.png';
        this.bulkList[0].eles[5].active = true;

        break;
      case 'tb':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/TB3.png';
        this.bulkList[0].eles[6].active = true;

        break;
      case 'sd':
        this.bulkList[0].eles[indexBulk].image = 'assets/images/SD3.png';
        this.bulkList[0].eles[7].active = true;

        break;

      default:
        break;
    }
    this.bulkList[0].eles.forEach((item: any, indexEle: any) => {
      this.disableElementWithOvalInactive(
        this.bulkList[0].eles[indexEle],
        indexEle
      );
    });

    this.finalRequestAssembler[index].BULK = this.bulkList[0].eles[indexBulk].text;
    this.finalRequestAssembler[index].BBL = this.bulkList[0].eles[indexBulk].bbl;

    const allOvalEnabled = this.bulkList[0].eles[indexBulk].ovalInfo.filter(
      (ele: any) => ele.active === true
    );

    if (
      this.listOfEnabledElements.length <
      this.bulkList[0].eles[indexBulk].ovalInfo.length
    ) {
      if (this.listOfEnabledElements.length === 0) {
        this.listOfEnabledElements.push(allOvalEnabled[0]);
      }

      this.listOfEnabledElements = [
        ...this.listOfEnabledElements,
        ...allOvalEnabled.filter((oval: any) => 
          !this.listOfEnabledElements.some(
            (element: any) => element.secondValue === oval.secondValue && element.value === oval.value
          )
        )
      ];
    }
    this.loadingGif = false;
    console.log(allOvalEnabled,this.listOfEnabledElements,'this.listOfEnabledElements.length',this.bulkList[0].eles[indexBulk].ovalInfo.length,this.listOfEnabledElements.length)
    if (
      this.bulkList[0].eles[indexBulk].ovalInfo.length ==
      this.listOfEnabledElements.length
    ) {
      // this.enableAssembleBtn = false;
      // this.disabledTemporalAssaemble = false;
      // this.assembleIsActivated = false;
      this.bulkIsSelected = true;
    } else {
      this.bulkIsSelected = false;
      // this.enableAssembleBtn = true;
      // this.disabledTemporalAssaemble = true;
      // this.assembleIsActivated = true;
    }
    // this.bulkIsSelected = true;
    // if (
    //   this.listOfEnabledElements.length ===
    //   this.bulkList[0].eles[indexBulk].ovalInfo.length
    // ) {
    //   this.bulkIsSelected = true;
    // }
    const bulksActivated = this.bulkList[0].eles.filter(
      (value: any) => value.active === true
    );
    this.useArrayValue[0].BULK = bulksActivated[0].text;
    if (this.visualizedYards) {
      this.getYards(this.useArrayValue);
    }
  }

  selectBulkImg(index: any) {
    if(this.bulkList[0].eles[index].type === 'disabled') return
    
    this.bulkList[0].eles.forEach((element: any) => {
      element.ovalInfo.forEach((item: any) => {
        item.active = false;
      });
    });
    this.bulkList[0].eles.forEach((item: any) => {
      item.active = false;
    });
    this.bulkList[0].eles[index].ovalInfo.forEach((element: any) => {
      if (element.enabled) {
        element.active = true;
      }
    });
    this.bulkList[0].eles[index].active = true;
    switch (this.bulkList[0].eles[index].text) {
      case 'QH':
        this.bulkList[0].eles[index].image = 'assets/images/QH3.png';
        break;
      case 'BH':
        this.bulkList[0].eles[index].image = 'assets/images/BH3.png';
        break;
      case 'AH':
        this.bulkList[0].eles[index].image = 'assets/images/AH3.png';
        break;
      case 'QGF':
        this.bulkList[0].eles[index].image = 'assets/images/QGF3.png';
        break;
      case 'ST_BH':
        this.bulkList[0].eles[index].image = 'assets/images/STBH3.png';
        break;
      case 'ST_AH':
        this.bulkList[0].eles[index].image = 'assets/images/STAH3.png';
        break;
      case 'TB':
        this.bulkList[0].eles[index].image = 'assets/images/TB3.png';
        break;
      case 'SD':
        this.bulkList[0].eles[index].image = 'assets/images/SD3.png';
        break;

      default:
        break;
    }
    this.bulkList[0].eles.forEach((item: any, indexEle: any) => {
      this.disableElementWithOvalInactive(
        this.bulkList[0].eles[indexEle],
        indexEle
      );
    });
    this.finalRequestAssembler.forEach(
      (element: any, finalRequestIndex: any) => {
        this.finalRequestAssembler[finalRequestIndex].BULK =
          this.bulkList[0].eles[index].text;
        this.finalRequestAssembler[finalRequestIndex].BBL =
          this.bulkList[0].eles[finalRequestIndex].bbl;
      }
    );

    this.listOfEnabledElements = [];
    const allOvalEnabled = this.bulkList[0].eles[index].ovalInfo.filter(
      (ele: any) => ele.active === true && ele.enabled === true
    );

    if (
      this.listOfEnabledElements.length <
      this.bulkList[0].eles[index].ovalInfo.length
    ) {
      this.listOfEnabledElements = allOvalEnabled;
    }
    this.loadingGif = false;
    if (
      this.bulkList[0].eles[index].ovalInfo.length ==
      this.listOfEnabledElements.length
    ) {
      this.bulkIsSelected = true;
      // this.enableAssembleBtn = false;
      // this.disabledTemporalAssaemble = false;
      // this.assembleIsActivated = false;
    } else {
      // this.enableAssembleBtn = true;
      // this.disabledTemporalAssaemble = true;
      // this.assembleIsActivated = true;
      this.bulkIsSelected = false;
    }
    //this.bulkIsSelected = true;

    const bulksActivated = this.bulkList[0].eles.filter(
      (value: any) => value.active === true
    );
    this.useArrayValue[0].BULK = bulksActivated[0].text;
    if (this.visualizedYards) {
      this.getYards(this.useArrayValue);
    }

  }
  /**
   *  Method that remembers and load the last tab selected
   */
  loadLastTabSelected() {
    const bulkTab = sessionStorage.getItem('bulkTabZA');
    const regulationsTab = sessionStorage.getItem('regulationsTabZA');
    const layersTab = sessionStorage.getItem('layersTabZA');
    let click: any;
    this.infoTypeData.forEach((element) => {
      element.active = false;
    });
    this.bulkSelected = false;
    this.regulationSelected = false;
    this.layerSelected = false;

    if (bulkTab === 'true' || (!bulkTab && !regulationsTab && !layersTab)) {
      this.bulkSelected = true;
      this.infoTypeData[0].active = !this.infoTypeData[0].active;
    } else if (regulationsTab) {
      this.regulationSelected = true;
      this.infoTypeData[1].active = !this.infoTypeData[1].active;
    } else if (layersTab) {
      this.infoTypeData[2].active = !this.infoTypeData[2].active;
      this.layerSelected = true;
      setTimeout(() => {
        click = window.document.getElementById('layers')?.click();
      }, 1);
      // this.getItemStateOnInit();
    }
  }

  iterateData() {
    const transformedData = {
      ...this.assembleData.message.REGULATIONS.CAP_07,
      CIRCLES: this.assembleData.message.REGULATIONS.CAP_07.CIRCLES.map(
        (circle: any) => ({
          ...circle,
          SECTION: Object.keys(circle.SECTION).map(
            (key) => circle.SECTION[key]
          ),
        })
      ),
    };

    this.capSevenArray = transformedData;
    console.log('capSevenDataaaa', transformedData);
  }

  getFormatedTxt(txt: any): string {
    return txt && txt?.includes('\n') ? txt?.replace(/\n/g, '<br>') : txt;
  }

  getFormatedZonedist(txt: any): string {
    return txt && txt?.includes('\n') ? txt?.replace(/<\/a>/g, '<br>') : txt;
  }

  isNotArray(value: any) {
    return !Array.isArray(value);
  }

  getDivHeight(id: any) {
    const idKey = id.replace('value','key');
    const idValue = id;
    const divValue = document.getElementById(idValue);
    const divKey = document.getElementById(idKey);

    if (divValue && divKey) {
      const textContent = divValue.innerText;
      if( (divValue.getBoundingClientRect().height > divKey.getBoundingClientRect().height) && textContent.length){
        return divValue.getBoundingClientRect().height + 'px';
      }
      else {
        return divKey.getBoundingClientRect().height + 'px';
      }
    } else {
      //console.error(`El elemento con el ID ${id} no existe.`);
      return null;
    }
  }

  /**
   * Method that construct the regulationsList data
   */
  constructRegulationsList() {
    this.iterateData();
    console.log('capSevenArray dataaa', this.capSevenArray);
    this.accordionActiveIndex = this.accordionMemorizedActiveIndex;
    this.regulationsOptions = [
      {
        cap: 1,
        noOvalData: false,
        optionTitle: `1. ${this.assembleData.message.REGULATIONS.CAP_01.NAME}`,
        ZONEDIST:
          this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.ZONEDIST,
        overlay:
          this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.OVERLAY,
        BULK: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.BULK,
        key: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.KEY
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.KEY
            )
          : null,
        value: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.VALUE[0]
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.VALUE[0]
            )
          : null,
        rData: {
          key: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.R?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.R?.VALUE,
        },
        cfData: {
          key: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.CF?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.CF?.VALUE,
        },
        cData: {
          key: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.C?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.C?.VALUE,
        },
        mData: {
          key: this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.M?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.M?.VALUE,
        },
        hasLetters:
          this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.R?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.C?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.CF?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_01.CIRCLES[0]?.M?.KEY
            ? true
            : false,
      },
      {
        cap: 2,
        noOvalData: false,
        optionTitle: `2. Lot coverage (max.) / Open Space (min.)`,
        ZONEDIST:
          this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.ZONEDIST,
        overlay:
          this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.OVERLAY,
        BULK: this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.BULK,
        rData: {
          key: this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.R?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.R?.VALUE,
        },
        cfData: {
          key: this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.CF?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.CF?.VALUE,
        },
        cData: {
          key: this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.C?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.C?.VALUE,
        },
        mData: {
          key: this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.M?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.M?.VALUE,
        },
        hasLetters:
          this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.R?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.C?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.CF?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_02.CIRCLES[0]?.M?.KEY
            ? true
            : false,
      },
      {
        cap: 3,
        noOvalData: false,
        optionTitle: `3. ${this.assembleData.message.REGULATIONS.CAP_03.NAME}`,
        ZONEDIST:
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.ZONEDIST,
        overlay:
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.OVERLAY,
        BULK: this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.BULK,
        rData: {
          key: this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.R?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.R?.VALUE,
        },
        cfData: {
          key: this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.CF?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.CF?.VALUE,
        },
        cData: {
          key: this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.C?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.C?.VALUE,
        },
        mData: {
          key: this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.M?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.M?.VALUE,
        },
        percentData: {
          key: this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.R_PERCENT?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.R_PERCENT?.VALUE,
        },
        hasLetters:
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.R?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.C?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.CF?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.M?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_03.CIRCLES[0]?.R_PERCENT?.KEY
            ? true
            : false,
      },
      {
        cap: 4,
        noOvalData: false,
        optionTitle: `4. ${this.assembleData.message.REGULATIONS.CAP_04.NAME}`,
        ZONEDIST:
          this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.ZONEDIST,
        overlay:
          this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.OVERLAY,
        BULK: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.BULK,
        key: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.KEY
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.KEY
            )
          : null,
        value: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.VALUE[0]
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.VALUE[0]
            )
          : null,
        rData: {
          key: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.R?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.R?.VALUE,
        },
        cfData: {
          key: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.CF?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.CF?.VALUE,
        },
        cData: {
          key: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.C?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.C?.VALUE,
        },
        mData: {
          key: this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.M?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.M?.VALUE,
        },
        hasLetters:
          this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.R?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.C?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.CF?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_04.CIRCLES[0]?.M?.KEY
            ? true
            : false,
      },
      {
        cap: 5,
        noOvalData: true,
        optionTitle: `5. ${this.assembleData.message.REGULATIONS.CAP_05.NAME}`,
        ZONEDIST:
          this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.ZONEDIST,
        overlay:
          this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.OVERLAY,
        BULK: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.BULK,
        key: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.KEY
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.KEY
            )
          : null,
        value: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.VALUE[0]
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.VALUE[0]
            )
          : null,
        rData: {
          key: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.R?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.R?.VALUE,
        },
        cfData: {
          key: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.CF?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.CF?.VALUE,
        },
        cData: {
          key: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.C?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.C?.VALUE,
        },
        mData: {
          key: this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.M?.KEY,
          value:
            this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.M?.VALUE,
        },
        hasLetters:
          this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.R?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.C?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.CF?.KEY ||
          this.assembleData.message.REGULATIONS.CAP_05.CIRCLES[0]?.M?.KEY
            ? true
            : false,
      },
      {
        cap: 6,
        noOvalData: false,
        optionTitle: `6. ${this.assembleData.message.REGULATIONS?.CAP_06?.NAME}`,
        ZONEDIST: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
          ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.ZONEDIST
          : null,
        overlay: '',
        BULK: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
          ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.BULK
          : null,
        key: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.KEY
            )
          : null,
        value: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
          ? this.sanitizer.bypassSecurityTrustHtml(
              this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]
                ?.VALUE[0]
            )
          : null,
        rData: {
          key: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.R?.KEY
            : null,
          value: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.R
                ?.VALUE
            : null,
        },
        cfData: {
          key: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.CF?.KEY
            : null,
          value: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.CF
                ?.VALUE
            : null,
        },
        cData: {
          key: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.C?.KEY
            : null,
          value: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.C
                ?.VALUE
            : null,
        },
        mData: {
          key: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.M?.KEY
            : null,
          value: this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES
            ? this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.M
                ?.VALUE
            : null,
        },
        hasLetters:
          this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES &&
          (this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.R?.KEY ||
            this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.C?.KEY ||
            this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.CF
              ?.KEY ||
            this.assembleData.message.REGULATIONS?.CAP_06?.CIRCLES[0]?.M?.KEY)
            ? true
            : false,
      },
      {
        cap: 7,
        noOvalData: false,
        optionTitle: `7. ${this.assembleData.message.REGULATIONS?.CAP_07?.NAME}`,
        ZONEDIST:
          this.assembleData.message.REGULATIONS?.CAP_07?.CIRCLES[0]?.ZONEDIST,
        overlay:
          this.assembleData.message.REGULATIONS.CAP_07.CIRCLES[0]?.OVERLAY,
        BULK: this.assembleData.message.REGULATIONS?.CAP_07?.CIRCLES[0]?.BULK,
      },
    ];
    console.log('test data regulations', this.regulationsOptions);
  }

  toggleAccordion(index: number): void {
    this.accordionActiveIndex[index] = !this.accordionActiveIndex[index];
    this.updateAllOpenStatus();
  }

  handleAccordion(openAll: boolean): void {
    this.accordionActiveIndex = this.accordionActiveIndex.map(() => openAll);
    this.allOpen = openAll;
    this.accordionMemorizedActiveIndex = this.accordionActiveIndex;
  }

  private updateAllOpenStatus(): void {
    this.allOpen = this.accordionActiveIndex.every((isOpen) => isOpen);
  }

  /**
   * Method that constructs the layerList info
   */
  constructLayersList() {
    this.layersList = [
      {
        label: 'Within 100’ of a Wide Street',
        id: 0,
        enabled: this.switchWideStreet.enabled,
        optionName: 'wideStreet',
        switch: this.switchWideStreet,
        border: '#f1f1f1',
        optionsList: null,
      },
      {
        label: 'Within 100’ of  Corner',
        enabled: this.switchCorner.enabled,
        id: 1,
        optionName: 'corner',
        switch: this.switchCorner,
        percentage: '0',
        optionsList: null,
      },
      {
        label: 'Short Dimension of the Block',
        enabled: this.switchDimensionBlock.enabled,
        id: 2,
        optionName: 'dimensionBlock',
        switch: this.switchDimensionBlock,
        percentage: '0',
        optionsList: null,
      },
      {
        label: 'Lot Type Portion',
        enabled: this.switchTypePortion.enabled,
        id: 3,
        optionName: 'typePortion',
        switch: this.switchTypePortion,
        percentage: '92px',
        color: '#445244',
        optionsList: [
          {
            label: 'Corner Lot Portion',
            enabled: this.cornerLotPortion,
            id: 1,
            optionName: 'cornerLotPortion',
            colorList: null,
            parentName: 'typePortion',
          },
          {
            label: 'Through Lot Portion',
            enabled: this.throughLotPortion,
            id: 2,
            optionName: 'throughLotPortion',
            colorList: null,
            parentName: 'typePortion',
          },
          {
            label: 'Interior Lot Portion',
            enabled: this.interiorLotPortion,
            id: 3,
            colorList: null,
            optionName: 'interiorLotPortion',
            parentName: 'typePortion',
          },
        ],
      },
      {
        label: 'Lot Line Type',
        enabled: this.switchLotLine.enabled,
        id: 4,
        optionName: 'lineType',
        switch: this.switchLotLine,
        percentage: '92px',
        optionsList: [
          {
            label: 'Front Lot Line',
            enabled: this.frontLotLines,
            id: 1,
            optionName: 'frontLotLine',
            colorList: null,
            parentName: 'lineType',
          },
          {
            label: 'Side Lot Line',
            enabled: this.sideLotLines,
            id: 2,
            optionName: 'sideLotLine',
            colorList: null,
            parentName: 'lineType',
          },
          {
            label: 'Rear Lot Line',
            enabled: this.rearLotLine,
            id: 3,
            colorList: null,
            optionName: 'rearLotLine',
            parentName: 'lineType',
          },
        ],
      },

      {
        label: 'Yards',
        enabled: this.switchYard.enabled,
        id: 8,
        optionName: 'yard',
        switch: this.switchYard,
        percentage: '92px',
        optionsList: [
          {
            label: 'Front Yard',
            enabled: this.frontYard,
            id: 1,
            optionName: 'frontYard',
            parentName: 'yard',
          },
          {
            label: 'Side Yard',
            enabled: this.sideYard,
            id: 2,
            optionName: 'sideYard',
            parentName: 'yard',
          },
          {
            label: 'Rear Yard',
            enabled: this.rearYard,
            id: 3,
            optionName: 'rearYard',
            parentName: 'yard',
          },
        ],
      },
      {
        label: 'Setback Line',
        enabled: this.switchSetback.enabled,
        id: 9,
        optionName: 'setback',
        switch: this.switchSetback,
        optionsList: null,
      },
      {
        label: 'Adjacent R1-R5',
        enabled: this.switchAdjacent.enabled,
        id: 10,
        optionName: 'adjacent',
        switch: this.switchAdjacent,
        color: '#D3E660',
        optionsList: null,
      },
      {
        label: 'Adjacent R5D & R6B',
        enabled: this.switchAdjacentR5D.enabled,
        id: 11,
        optionName: 'adjacentR5D',
        switch: this.switchAdjacentR5D,
        optionsList: null,
      },
      {
        label: 'Lot Dimensions',
        enabled: this.switchLotDimension.enabled,
        id: 11,
        optionName: 'lotDimension',
        switch: this.switchLotDimension,
        optionsList: null,
      },
    ];
  }

  /**
   * Method that gets flag that makes bigger the accordion
   * @param event event value
   */
  getBiggerAccordion(event: boolean) {
    this.biggerAccordion.emit(event);
  }

  isStringJSON(value: any) {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }

  constructConfigListAccordingBBL(
    itemListSelectedArray?: any[],
    index?: number
  ) {
    const newDataToUpdateAccordingBBL = [];
    const configArray = {
      bbl: sessionStorage.getItem('bbl'),
      useSelectionList: this.useSelectionList,
      useSecondList: this.useSecondList,
      useThirdList: this.useThirdList,
      bulkConfig: this.bulkList,
      btnAssemble: this.assembleIsActivated,
      btnTags: this.tagIsActivated,
      btnTagsEnabled: this.tagBtnIsEnabled,
      useIsSelected: this.useIsSelected,
      bulkIsSelected: this.bulkIsSelected,
    };
    let bblExistDataList: any[] = this.selectUseSelectedConfig.includes('"')
      ? JSON.parse(this.selectUseSelectedConfig)?.find(
          (ele: any) => ele.bbl === configArray.bbl
        )
      : this.selectUseSelectedConfig?.find(
          (ele: any) => ele.bbl === configArray.bbl
        );

    let bblRestantDataList: any[] = [];
    if (this.selectUseSelectedConfig.includes('"')) {
      JSON.parse(this.selectUseSelectedConfig)?.forEach((element: any) => {
        bblRestantDataList.push(element);
      });
    }
    let valueSelectUseSelectedConfig: any = this.selectUseSelectedConfig;

    if (!!this.assembleIsActivated) {
      if (!!bblExistDataList) {
        newDataToUpdateAccordingBBL?.push(configArray);
        if (bblRestantDataList) {
          valueSelectUseSelectedConfig = [];
          bblRestantDataList.push(configArray);
          const index = bblRestantDataList.findIndex(
            (item) => item.bbl === configArray.bbl
          );
          if (index !== -1) {
            bblRestantDataList[index] = configArray;
          } else {
            bblRestantDataList.push(configArray);
          }
          valueSelectUseSelectedConfig = bblRestantDataList;
          this.selectUseSelectedConfig = valueSelectUseSelectedConfig;
        } else {
          this.selectUseSelectedConfig = newDataToUpdateAccordingBBL;
        }
      } else {
        if (valueSelectUseSelectedConfig.includes('"')) {
          this.selectUseSelectedConfig = JSON.parse(
            valueSelectUseSelectedConfig
          );
          this.selectUseSelectedConfig?.push(configArray);
        } else {
          valueSelectUseSelectedConfig?.push(configArray);
          this.selectUseSelectedConfig = valueSelectUseSelectedConfig;
        }
      }

      // sessionStorage.setItem(
      //   'zoningAnalysisOptionsSelectedByBBL',
      //   JSON.stringify(this.selectUseSelectedConfig)
      // );
    }
  }

  mergeObjectsWithTrueValues(
    obj1: any,
    obj2?: any,
    obj3?: any,
    obj4?: any,
    obj5?: any,
    obj6?: any
  ) {
    const emptyObj = {};
    const result: any = {};

    const keys = new Set(
      obj1 && obj2 && !obj3 && !obj4 && !obj5 && !obj6
        ? [...Object.keys(obj1), ...Object.keys(obj2)]
        : obj1 && obj2 && obj3 && !obj4 && !obj5 && !obj6
        ? [...Object.keys(obj1), ...Object.keys(obj2), ...Object.keys(obj3)]
        : obj1 && obj2 && obj3 && obj4 && !obj5 && !obj6
        ? [
            ...Object.keys(obj1),
            ...Object.keys(obj2),
            ...Object.keys(obj3),
            ...Object.keys(obj4),
          ]
        : obj1 && obj2 && obj3 && obj4 && obj5 && !obj6
        ? [
            ...Object.keys(obj1),
            ...Object.keys(obj2),
            ...Object.keys(obj3),
            ...Object.keys(obj4),
            ...Object.keys(obj5),
          ]
        : obj1 && obj2 && obj3 && obj4 && obj5 && obj6
        ? [
            ...Object.keys(obj1),
            ...Object.keys(obj2),
            ...Object.keys(obj3),
            ...Object.keys(obj4),
            ...Object.keys(obj5),
            ...Object.keys(obj6),
          ]
        : null
    );

    if (obj1 && obj2 && !obj3 && !obj4 && !obj5 && !obj6) {
      keys.forEach((key) => {
        if (obj1[key] === true || obj2[key] === true) {
          result[key] = true;
        } else {
          result[key] = false;
        }
      });
    } else if (obj1 && obj2 && obj3 && !obj4 && !obj5 && !obj6) {
      keys.forEach((key) => {
        if (obj1[key] === true || obj2[key] === true || obj3[key] === true) {
          result[key] = true;
        } else {
          result[key] = false;
        }
      });
    } else if (obj1 && obj2 && obj3 && obj4 && !obj5 && !obj6) {
      keys.forEach((key) => {
        if (
          obj1[key] === true ||
          obj2[key] === true ||
          obj3[key] === true ||
          obj4[key] === true
        ) {
          result[key] = true;
        } else {
          result[key] = false;
        }
      });
    } else if (obj1 && obj2 && obj3 && obj4 && obj5 && !obj6) {
      keys.forEach((key) => {
        if (
          obj1[key] === true ||
          obj2[key] === true ||
          obj3[key] === true ||
          obj4[key] === true ||
          obj5[key] === true
        ) {
          result[key] = true;
        } else {
          result[key] = false;
        }
      });
    } else if (obj1 && obj2 && obj3 && obj4 && obj5 && obj6) {
      keys.forEach((key) => {
        if (
          obj1[key] === true ||
          obj2[key] === true ||
          obj3[key] === true ||
          obj4[key] === true ||
          obj5[key] === true ||
          obj6[key] === true
        ) {
          result[key] = true;
        } else {
          result[key] = false;
        }
      });
    }

    return result;
  }

  getBulks(request: any, indexUseItem?: number, rowIndex?: number) {
    console.log('request getBulk',request)
    const bulks = this.useBulkMethods.getBulk(request).map((e: any) => {
      return e;
    });
    console.log('test bulks', bulks);
    this.bulksConfigData = bulks;
    const bulksListAccordingArray: any[] = [];
    bulks.forEach((bulk: any) => {
      bulksListAccordingArray.push(bulk.BULKS);
    });
    if (bulksListAccordingArray.length === 1) {
      this.bulkCombination = bulks[0].BULKS;
    } else if (bulksListAccordingArray.length === 2) {
      this.bulkCombination = this.mergeObjectsWithTrueValues(
        bulks[0].BULKS,
        bulks[1].BULKS
      );
    } else if (bulksListAccordingArray.length === 3) {
      this.bulkCombination = this.mergeObjectsWithTrueValues(
        bulks[0].BULKS,
        bulks[1].BULKS,
        bulks[2].BULKS
      );
    } else if (bulksListAccordingArray.length === 4) {
      this.bulkCombination = this.mergeObjectsWithTrueValues(
        bulks[0].BULKS,
        bulks[1].BULKS,
        bulks[2].BULKS,
        bulks[3].BULKS
      );
    } else if (bulksListAccordingArray.length === 5) {
      this.bulkCombination = this.mergeObjectsWithTrueValues(
        bulks[0].BULKS,
        bulks[1].BULKS,
        bulks[2].BULKS,
        bulks[3].BULKS,
        bulks[4].BULKS
      );
    } else if (bulksListAccordingArray.length === 6) {
      this.bulkCombination = this.mergeObjectsWithTrueValues(
        bulks[0].BULKS,
        bulks[1].BULKS,
        bulks[2].BULKS,
        bulks[3].BULKS,
        bulks[4].BULKS,
        bulks[5].BULKS
      );
    }

    console.log('teest', this.bulkCombination);

    this.rowConfigData.forEach((element: any) => {
      if (
        this.rowConfigData.length < 1 &&
        this.rowConfigData &&
        element.R === false &&
        this.rowConfigData &&
        element.CF === false &&
        this.rowConfigData &&
        element.C === false &&
        this.rowConfigData &&
        element.M === false
      ) {
        this.bulkList = [];
        this.resetRowsBulkList();
        this.rearYardList = [];
        this.resetRowsYardList();
      } else {
        this.updateRowsBulkList(
          indexUseItem!,
          rowIndex!,
          bulksListAccordingArray
        );
      }
    });
  }

  activeYardsByDefault() {
    (this.rearYardList[0].eles[0].type =
      this.yardsConfigData[0].A === true ? 'selectable' : 'disabled'),
      (this.rearYardList[0].eles[1].type =
        this.yardsConfigData[0].B_1 === true ||
        this.yardsConfigData[0].B_2 === true ||
        this.yardsConfigData[0].B_3 === true
          ? 'selectable'
          : 'disabled'),
      (this.rearYardList[0].eles[2].type =
        this.yardsConfigData[0].C === true ? 'selectable' : 'disabled'),
      (this.rearYardList[0].eles[0].image =
        this.yardsConfigData[0].A === true
          ? 'assets/images/rear-yard-one.png'
          : 'assets/images/rear-yard-one-disabled.png');

    this.rearYardList[0].eles[1].image =
      this.yardsConfigData[0].B_1 === true &&
      this.yardsConfigData[0].A === false
        ? 'assets/images/rear-yard-vertical.png'
        : 'assets/images/rear-yard-vertical-disabled.png';
    this.rearYardList[0].eles[2].image =
      this.yardsConfigData[0].C === true &&
      this.yardsConfigData[0].A === false &&
      this.yardsConfigData[0].B_1 === false
        ? 'assets/images/rear-yard-two.png'
        : 'assets/images/rear-yard-two-disabled.png';

    this.rearYardList[0].eles[0].buildingOpt[0].selected =
      this.yardsConfigData[0].A === true ? true : false;

    this.rearYardList[0].eles[1].buildingOpt[0].selected =
      this.yardsConfigData[0].B_1 === true &&
      this.yardsConfigData[0].A === false
        ? true
        : false;
    this.rearYardList[0].eles[1].buildingOpt[1].selected = false;
    this.rearYardList[0].eles[1].buildingOpt[2].selected = false;

    this.rearYardList[0].eles[2].buildingOpt[0].selected =
      this.yardsConfigData[0].C === true &&
      this.yardsConfigData[0].B_1 === false &&
      this.yardsConfigData[0].A === false
        ? true
        : false;

    this.rearYardList[0].eles[0].active =
      this.yardsConfigData[0].A === true ? true : false;
    this.rearYardList[0].eles[1].active =
      this.yardsConfigData[0].B_1 === true &&
      this.yardsConfigData[0].A === false
        ? true
        : false;
    this.rearYardList[0].eles[2].active =
      this.yardsConfigData[0].C &&
      this.yardsConfigData[0].B_1 === false &&
      (this.yardsConfigData[0].A === false) === true
        ? true
        : false;
  }

  buildEnvelopeRequest() {
    const objYardSelected = {
      YARD_TYPE: this.rearYardList[0].eles[0].buildingOpt[0].selected
        ? 'A'
        : this.rearYardList[0].eles[1].buildingOpt[0].selected
        ? 'B_1'
        : this.rearYardList[0].eles[1].buildingOpt[1].selected
        ? 'B_2'
        : this.rearYardList[0].eles[1].buildingOpt[2].selected
        ? 'B_3'
        : this.rearYardList[0].eles[2].buildingOpt[0].selected
        ? 'C'
        : '',
    };
    this.finalRequestAssembler.forEach((element: any) => {
      element.YARD_TYPE = objYardSelected.YARD_TYPE;
    });
  }

  getYards(request: any) {
    const yards = this.useBulkMethods.getYard(request).map((e: any) => {
      return e;
    });
    console.log('teeeeeest', yards);
    this.yardsConfigData = yards;
    this.activeYardsByDefault();
    this.updateRowsYardList();
    this.buildEnvelopeRequest();
  }

  removeAtIndex = (arr: any, index: any) => {
    if (index < 0 || index >= arr.length) {
      return arr; // Retornar el arreglo original si el índice no es válido
    }
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  };

  /**
   * Method that select item first row
   * @param index index value
   */
  selectItemRow(index: number, rowIndex: number) {

    if(!this.useSelectionList[rowIndex!].selectOptions[index].enabled) return

    this.bulkIsSelected = false;
    this.listOfEnabledElements = [];
    this.resetRowsYardList();
    let arrayValue: any[] = [];
    this.useSelectionList[rowIndex!].selectOptions[index].active = !this.useSelectionList[rowIndex!].selectOptions[index].active;

    let optionsSelected: any[] = [];
    if (
      !this.useSelectionList[rowIndex!].selectOptions[0].active &&
      !this.useSelectionList[rowIndex!].selectOptions[1].active &&
      !this.useSelectionList[rowIndex!].selectOptions[2].active &&
      !this.useSelectionList[rowIndex!].selectOptions[3].active
    ) {
      this.finalRequestAssembler = this.removeAtIndex(
        this.finalRequestAssembler,
        rowIndex
      );
      if (
        this.useBulkAvailableInfo.length !== this.finalRequestAssembler.length
      ) {
        this.enableAssembleBtn = false;
        this.assembleIsActivated = false;
      }
    }
    if (
      (!!this.useSelectionList[rowIndex!].selectOptions[0].active &&
        !this.useSelectionList[rowIndex!].selectOptions[1].active &&
        !this.useSelectionList[rowIndex!].selectOptions[2].active &&
        !this.useSelectionList[rowIndex!].selectOptions[3].active) ||
      (!this.useSelectionList[rowIndex!].selectOptions[0].active &&
        !this.useSelectionList[rowIndex!].selectOptions[index].active)
    ) {
      this.useSelectionList[rowIndex!].useBarList.forEach((opt: any) => {
        if (!!opt.visible) {
          opt.disabled = true;
        }
      });
    }

    this.useSelectionList.forEach((element: any, forIndex: any) => {
      const zonedist = this.useSelectionList[rowIndex!].selectInfo[0].type
      const overlay = this.useSelectionList[rowIndex!].selectInfo[1].type

      let lessThanBar = (
        (this.useBulkAvailableInfo[forIndex].ZONEDIST == zonedist && this.useBulkAvailableInfo[forIndex].OVERLAY == overlay) 
        || Object.keys(this.useSelectionList[forIndex]?.useBarList[0]).length <= 1 ?
        false : this.useSelectionList[forIndex]?.useBarList[0]?.values[0]?.selected)

      let moreThanBar = (
        (this.useBulkAvailableInfo[forIndex].ZONEDIST == zonedist && this.useBulkAvailableInfo[forIndex].OVERLAY == overlay) 
        || Object.keys(this.useSelectionList[forIndex]?.useBarList[0]).length <= 1 ?
        false : this.useSelectionList[forIndex]?.useBarList[0]?.values[1]?.selected)

      let higherThanBar = (
        (this.useBulkAvailableInfo[forIndex].ZONEDIST == zonedist && this.useBulkAvailableInfo[forIndex].OVERLAY == overlay)  
        || Object.keys(this.useSelectionList[forIndex]?.useBarList[0]).length <= 1 ?
        true : this.useSelectionList[forIndex]?.useBarList[0]?.values[2]?.selected)

      const value = {
        R:
          index === 0 && element.selectOptions[index].text === 'R'
            ? element?.selectOptions[0].active
            : element?.selectOptions[0].active,
        CF:
          index === 1 && element.selectOptions[index].text === 'CF'
            ? element?.selectOptions[1].active
            : element?.selectOptions[1].active,
        C:
          index === 2 && element.selectOptions[index].text === 'C'
            ? element?.selectOptions[2].active
            : element?.selectOptions[2].active,
        M:
          index === 3 && element.selectOptions[index].text === 'M'
            ? element?.selectOptions[3].active
            : element?.selectOptions[3].active,
        INDEX: this.useBulkAvailableInfo[forIndex]?.INDEX,
        R_QUANTITY: !this.useSelectionList[forIndex]?.selectOptions[0].active
          ? 0
          : element?.selectOptions[0].active &&
            !element?.selectOptions[1].active &&
            !element?.selectOptions[2].active &&
            !element?.selectOptions[3].active
          ? 100
          : element?.selectOptions[0].active && moreThanBar
          ? 50
          : element?.selectOptions[0].active && higherThanBar
          ? 80
          : element?.selectOptions[0].active && lessThanBar
          ? 15
          : 0,
      };
      const valueForYards = {
        R:
          index === 0 && element.selectOptions[index].text === 'R'
            ? element?.selectOptions[0].active
            : element?.selectOptions[0].active,
        CF:
          index === 1 && element.selectOptions[index].text === 'CF'
            ? element?.selectOptions[1].active
            : element?.selectOptions[1].active,
        C:
          index === 2 && element.selectOptions[index].text === 'C'
            ? element?.selectOptions[2].active
            : element?.selectOptions[2].active,
        M:
          index === 3 && element.selectOptions[index].text === 'M'
            ? element?.selectOptions[3].active
            : element?.selectOptions[3].active,
        INDEX: this.useBulkAvailableInfo[forIndex]?.INDEX,
        BULK: '',
      };
      this.useArrayValue = [];
      arrayValue.push(value);
      this.useArrayValue.push(valueForYards);
      if (this.useSelectionList[forIndex]?.useBarList[0].disabled === true) {
        if (
          this.useSelectionList[forIndex]?.useBarList[0]?.values &&
          this.useSelectionList[forIndex]?.useBarList[0]?.values[2]?.value
        ) {
          this.higherThanBar = true;
          this.moreThanBar = false;
        } else {
          this.higherThanBar = false;
          this.moreThanBar = true;
        }
        if (arrayValue[forIndex] && arrayValue[forIndex].INDEX && moreThanBar) {
          this.moreThanBar = true;
          this.higherThanBar = false;
          arrayValue[forIndex].R_QUANTITY = 50;
        //  console.log('this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[1]?.selected',this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[1]?.selected);
          if (this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[1] !== undefined) {
            //this.useSelectionList[forIndex].useBarList[0].values[1].selected = true;
          }
        } else if (
          arrayValue[forIndex] &&
          arrayValue[forIndex].INDEX &&
          higherThanBar
        ) {
          this.moreThanBar = false;
          this.higherThanBar = true;
          arrayValue[forIndex].R_QUANTITY = 80;
          if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[2] !== undefined){
          //  this.useSelectionList[forIndex].useBarList[0].values[2].selected = true;
          }
        }
      }
    });

    if (!!this.useSelectionList[rowIndex!].selectOptions[0].active) {
      optionsSelected.push('R');
    }

    if (!!this.useSelectionList[rowIndex!].selectOptions[1].active) {
      optionsSelected.push('CF');
    }

    if (!!this.useSelectionList[rowIndex!].selectOptions[2].active) {
      optionsSelected.push('C');
    }

    if (!!this.useSelectionList[rowIndex!].selectOptions[3].active) {
      optionsSelected.push('M');
    }

    if (
      this.useSelectionList[rowIndex]?.useBarList[0].values &&
      this.useSelectionList[rowIndex]?.useBarList[0].disabled === true &&
      this.moreThanBar
    ) {
      if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[1] !== undefined) {
      //  this.useSelectionList[rowIndex].useBarList[0].values[1].selected = true;
      }
      if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[2] !== undefined) {
        //this.useSelectionList[rowIndex].useBarList[0].values[2].selected = false;
      }
      if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[0] !== undefined) {
        //this.useSelectionList[rowIndex].useBarList[0].values[0].selected = false;
      }
  
      this.twentyFiveOrLessActive = 100;
    } else if (
      this.useSelectionList[rowIndex]?.useBarList[0]?.disabled === true &&
      this.higherThanBar
    ) {
      if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[2] !== undefined) {
      //  this.useSelectionList[rowIndex].useBarList[0].values[2].selected = true;
      }
      if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[1] !== undefined) {
        //this.useSelectionList[rowIndex].useBarList[0].values[1].selected = false;
      }
      if(this.useSelectionList[rowIndex]?.useBarList?.[0]?.values?.[0] !== undefined) {
        //this.useSelectionList[rowIndex].useBarList[0].values[0].selected = false;
      }
      this.twentyFiveOrLessActive = 100;
    }

    this.rowConfigData = arrayValue;
    console.log('getBulks',arrayValue, index, rowIndex)
    this.getBulks(arrayValue, index, rowIndex);

    if (
      !!this.useSelectionList[rowIndex!].selectOptions[0].active &&
      (this.useSelectionList[rowIndex!].selectOptions[1].active ||
        this.useSelectionList[rowIndex!].selectOptions[2].active ||
        this.useSelectionList[rowIndex!].selectOptions[3].active)
    ) {
      this.useSelectionList[rowIndex!].useBarList.forEach((opt: any) => {
        if (!!opt.visible) {
          opt.values.forEach((barOpt: any) => {
            barOpt.selected = false;
          });
        }
      });
    }

    let selectionDisabledVerifier: any[] = [];
    this.useSelectionList.forEach((element: any) => {
      element.selectOptions.forEach((ele: any, index: number) => {
        selectionDisabledVerifier.push(
          ele.active,
          ele.active,
          ele.active,
          ele.active
        );
      });
    });
    if (!selectionDisabledVerifier.includes(true)) {
      this.resetRowsBulkList();
      this.resetRowsYardList();
    }

    if (
      !!this.useSelectionList[rowIndex!].selectOptions[0].active &&
      (this.useSelectionList[rowIndex!].selectOptions[1].active ||
        this.useSelectionList[rowIndex!].selectOptions[2].active ||
        this.useSelectionList[rowIndex!].selectOptions[3].active)
    ) {
      this.useSelectionList[rowIndex!].useBarList.forEach((bar: any) => {
        bar.disabled = false;
      });

      this.useSelectionList[rowIndex!].useBarList.forEach((ele: any) => {
        if (ele.values?.length === 2) {
          ele.values[1].selected = true;
          this.twentyFiveOrLessActive = 80;
        } else if (ele?.values?.length === 3) {
          ele.values[2].selected = true;
          this.twentyFiveOrLessActive = 80;
        } else {
          ele.disabled = true;
          this.twentyFiveOrLessActive = 100;
        }
      });

      this.disabledTemporalAssaemble = true;
    }

    this.useIsSelected = false;
    const result:any = [];
    this.useSelectionList.forEach((use: any) => {
        const isGroupActive = use.selectOptions.some((item: { active: boolean; }) => item.active);
        result.push(isGroupActive);
    });
    this.useIsSelected = result.every((active: boolean) => active);

    let newObj;
    newObj = {
      INDEX: String(this.useBulkAvailableInfo[rowIndex]?.INDEX),
      USES: optionsSelected,
      BULK: '',
      ZONEDIST: this.useBulkAvailableInfo[rowIndex].ZONEDIST,
      OVERLAY: this.useBulkAvailableInfo[rowIndex].OVERLAY || '',
      R25: this.twentyFiveOrLessActive,
      YARD_TYPE: '',
    };
    this.updateOrPushObject(this.finalRequestAssembler, newObj);
  }

  updateOrPushObject(arr: any, newObj: any) {
    // Encontrar el índice del objeto con el mismo id
    const index = arr.findIndex((obj: any) => obj.INDEX === newObj.INDEX);

    if (index !== -1) {
      // Si el objeto existe, reemplázalo
      arr[index] = newObj;
      this.finalRequestAssembler = arr;
    } else {
      // Si no existe, agrega el nuevo objeto al final del arreglo
      this.finalRequestAssembler.push(newObj);
    }
    this.finalRequestAssembler = this.finalRequestAssembler.sort(
      (a: any, b: any) => a.INDEX - b.INDEX
    );
  }
  /**
   * Method that select the option from first row bar
   */
  selectBarItem(index: number, useSelectionIndex: any, barCntIndex: number) {
    let objectValue: any;
    this.useSelectionList[useSelectionIndex].useBarList.forEach(
      (element: any) => {
        if (!!element.visible) {
          element.values.forEach((opt: any) => {
            opt.selected = false;
          });
        }
      }
    );
    this.useSelectionList[useSelectionIndex].useBarList.forEach(
      (element: any) => {
        if (element.visible === true) {
          element.values[index].selected = true;
        }
      }
    );

    let arrayValue: any[] = [];

    if ( this.useSelectionList[0]?.useBarList[barCntIndex]?.values[index] ?.value === '25% or less') {
      this.twentyFiveOrLessActive = 15;
    } 
    else if ( this.useSelectionList[0]?.useBarList[barCntIndex]?.values[index] ?.value === 'More than 25%') {
      this.twentyFiveOrLessActive = 40;
    } else if ( this.useSelectionList[0]?.useBarList[barCntIndex]?.values[index] ?.value === 'Between') {
      this.twentyFiveOrLessActive = 50;
    } else if ( this.useSelectionList[0]?.useBarList[barCntIndex]?.values[index] ?.value === '65% or more') {
      this.twentyFiveOrLessActive = 80;
    } 
    this.useSelectionList.forEach((element: any, forIndex: any) => {
      const value = {
        R:
          index === 0 && element.selectOptions[index].text === 'R'
            ? element?.selectOptions[0].active
            : element?.selectOptions[0].active,
        CF:
          index === 1 && element.selectOptions[index].text === 'CF'
            ? element?.selectOptions[1].active
            : element?.selectOptions[1].active,
        C:
          index === 2 && element.selectOptions[index].text === 'C'
            ? element?.selectOptions[2].active
            : element?.selectOptions[2].active,
        M:
          index === 3 && element.selectOptions[index].text === 'M'
            ? element?.selectOptions[3].active
            : element?.selectOptions[3].active,
        INDEX: this.useBulkAvailableInfo[forIndex]?.INDEX,
        R_QUANTITY:
          element?.selectOptions[0].active &&
          element?.useBarList[barCntIndex]?.visible &&
          element?.useBarList[barCntIndex]?.values[0]?.selected &&
          element?.useBarList[barCntIndex]?.values[0]?.value === '25% or less'
            ? 15
            : element?.selectOptions[0].active &&
              element?.useBarList[barCntIndex]?.visible &&
              element?.useBarList[barCntIndex]?.values[1]?.selected &&
              element?.useBarList[barCntIndex]?.values[1]?.value ===
                'More than 25%'
            ? 40
            : element?.selectOptions[0].active &&
              element?.useBarList[barCntIndex]?.visible &&
              element?.useBarList[barCntIndex]?.values[1]?.selected &&
              element?.useBarList[barCntIndex]?.values[1]?.value === 'Between'
            ? 50
            : element?.selectOptions[0].active &&
              element?.useBarList[barCntIndex]?.visible &&
              element?.useBarList[barCntIndex]?.values[2]?.selected &&
              element?.useBarList[barCntIndex]?.values[2]?.value ===
                '65% or more'
            ? 80
            : 0,
      };
      arrayValue.push(value);
    });
    this.finalRequestAssembler[useSelectionIndex].R25 = this.twentyFiveOrLessActive

    this.getBulks(arrayValue, undefined, useSelectionIndex);
  }

  /**
   * Method that handle the bulkIption image
   * @param index index value
   */
  handleBulkOptionImage(index: number) {
    switch (this.bulkList[0].eles[index].text) {
      case 'Quality Housing':
        this.bulkList[0].eles[index].image =
          'assets/images/quality-housing.png';
        break;

      default:
        break;
    }
  }

  validateIfHaveMultipleCircles(ele: any): boolean {
    let value: boolean = false;
    if (ele === 1) {
      value = false;
    } else if (ele > 1) {
      value = true;
    }
    return value;
  }

  /**
   * Method that emit the flag that validates if containers are opened
   */
  validateContainer() {
    this.validateContainersOpened.emit('ZoningAnalysis');
  }

  hasNoNewLine(text: any): boolean {
    return !/\r|\n/.test(text);
  }

  get compareIsBblIsAssembled(): any {
    const bbl = sessionStorage.getItem('bbl');
    const regulationData:any = (localStorage.getItem(`assembleData_${bbl}`) ? true : false)
    const assembleActive:any  = (this.assembleData ? true : false)
    return (regulationData && assembleActive? true : false);
  }

  /**
   * Method that handled the tab selection
   * @param index index value
   */
  handleTab(index: number) {
    const bbl = sessionStorage.getItem('bbl');
    const regulationData: any = localStorage.getItem(`assembleData_${bbl}`);
    this.infoTypeData.forEach((element) => {
      element.active = false;
    });
    this.bulkSelected = false;
    this.regulationSelected = false;
    this.layerSelected = false;
    switch (this.infoTypeData[index].type) {
      case Constants.BULK:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.bulkSelected = this.infoTypeData[index].active;
        sessionStorage.setItem('bulkTabZA', String(this.bulkSelected));
        sessionStorage.setItem('regulationsTabZA', '');
        sessionStorage.setItem('layersTabZA', '');
        break;

      case Constants.REGULATIONS:
        this.accordionActiveIndex = this.regulationsOptions.map(() => false);
        this.assembleData = regulationData ? JSON.parse(regulationData) : null;
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.regulationSelected = this.infoTypeData[index].active;
        sessionStorage.setItem('bulkTabZA', '');
        sessionStorage.setItem(
          'regulationsTabZA',
          String(this.regulationSelected)
        );
        sessionStorage.setItem('layersTabZA', '');
        this.constructRegulationsList();
        break;

      case Constants.LAYERS:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.layerSelected = this.infoTypeData[index].active;
        sessionStorage.setItem('bulkTabZA', '');
        sessionStorage.setItem('regulationsTabZA', '');
        sessionStorage.setItem('layersTabZA', String(this.layerSelected));
        break;

      default:
        break;
    }
  }

  /**
   * Method that close the zoning analysis container
   */
  close() {
    this.navBarCloseService.turnOffOption(Constants.ZONING_ANALYSIS);
    this.closeItem.emit(Constants.ZONING_ANALYSIS);
  }

  /**
   * Method that handle the selection of sliverLawCheck
   * @param event event value
   */
  handleSliverLawCheck(event: any) {
    this.sliverLawCheck = event?.target?.checked;
  }

  /**
   * Method that handle the visualizedYards
   * @param event event value
   */
  handleVisualizedYards(event: any) {
    console.log(this.yardsConfigData);
    this.visualizedYards = event?.target?.checked;
    if (this.visualizedYards === false) {
      this.disabledYards();
      this.finalRequestAssembler.forEach((element: any) => {
        element.YARD_TYPE = false;
      });
    } else {
      if (
        !!this.yardsConfigData[0].A ||
        this.yardsConfigData[0].B_1 ||
        !!this.yardsConfigData[0].B_2 ||
        !!this.yardsConfigData[0].B_3 ||
        !!this.yardsConfigData[0].C
      ) {
        this.getYards(this.useArrayValue);
        this.activateYards();
        this.buildEnvelopeRequest();
      } else {
        this.finalRequestAssembler.forEach((element: any) => {
          element.YARD_TYPE = '';
        });
      }
    }

    this.loadingGif = false;
    this.disabledTemporalAssaemble = true;
    this.useIsSelected = true;
    this.bulkIsSelected = true;
  }

  disabledYards() {
    this.rearYardList[0].eles[0].image =
      'assets/images/rear-yard-one-disabled.png';
    this.rearYardList[0].eles[1].image =
      'assets/images/rear-yard-vertical-disabled.png';
    this.rearYardList[0].eles[2].image =
      'assets/images/rear-yard-two-disabled.png';
    this.rearYardList[0].eles[0].buildingOpt[0].selected = false;
    this.rearYardList[0].eles[1].buildingOpt[0].selected = false;
    this.rearYardList[0].eles[1].buildingOpt[1].selected = false;
    this.rearYardList[0].eles[1].buildingOpt[2].selected = false;
    this.rearYardList[0].eles[2].buildingOpt[0].selected = false;

    this.rearYardList[0].eles[0].active = false;
    this.rearYardList[0].eles[1].active = false;
    this.rearYardList[0].eles[2].active = false;

    this.rearYardList[0].eles[0].type = 'disabled';
    this.rearYardList[0].eles[1].type = 'disabled';
    this.rearYardList[0].eles[2].type = 'disabled';
  }

  activateYards() {
    this.rearYardList[0].eles[0].type =
      this.yardsConfigData[0].A === true ? 'selectable' : 'disabled';
    this.rearYardList[0].eles[1].type =
      this.yardsConfigData[0].B_1 === true ||
      this.yardsConfigData[0].B_2 === true ||
      this.yardsConfigData[0].B_3 === true
        ? 'selectable'
        : 'disabled';
    this.rearYardList[0].eles[2].type =
      this.yardsConfigData[0].C === true ? 'selectable' : 'disabled';
    this.rearYardList[0].eles[0].image = 'assets/images/rear-yard-one.png';
    this.rearYardList[0].eles[1].image =
      this.rearYardList[0].eles[1].type === 'disabled'
        ? 'assets/images/rear-yard-vertical-disabled.png'
        : this.rearYardList[0]?.eles[1]?.type === 'selectable' &&
          !this.rearYardList[0]?.eles[1]?.buildingOpt[0]?.selected &&
          !this.rearYardList[0]?.eles[1]?.buildingOpt[1]?.selected &&
          !this.rearYardList[0]?.eles[1]?.buildingOpt[2]?.selected
        ? 'assets/images/rear-yard-vertical-semi.png'
        : !!this.rearYardList[0]?.eles[1]?.buildingOpt[0]?.selected
        ? 'assets/images/rear-yard-vertical.png'
        : !!this.rearYardList[0]?.eles[1]?.buildingOpt[1]?.selected
        ? 'assets/images/rear-yard-vertical-typeb.png'
        : !!this.rearYardList[0]?.eles[1]?.buildingOpt[2]?.selected
        ? 'assets/images/rear-yard-vertical-typec.png'
        : 'assets/images/rear-yard-vertical-disabled.png';
    this.rearYardList[0].eles[2].image =
      this.rearYardList[0]?.eles[2]?.type === 'disabled'
        ? 'assets/images/rear-yard-two-disabled.png'
        : this.rearYardList[0]?.eles[2]?.type === 'selectable' &&
          this.rearYardList[0]?.eles[2]?.active === false
        ? 'assets/images/rear-yard-two-semi.png'
        : this.rearYardList[0]?.eles[2]?.type === 'selectable' &&
          this.rearYardList[0]?.eles[2]?.active === true
        ? 'assets/images/rear-yard-two.png'
        : 'assets/images/rear-yard-two-disabled.png';
    this.rearYardList[0].eles[0].buildingOpt[0].selected = true;
    this.rearYardList[0].eles[1].buildingOpt[0].selected = false;
    this.rearYardList[0].eles[1].buildingOpt[1].selected = false;
    this.rearYardList[0].eles[1].buildingOpt[2].selected = false;
    this.rearYardList[0].eles[2].buildingOpt[0].selected = false;

    this.rearYardList[0].eles[0].active = true;
    this.rearYardList[0].eles[1].active = false;
    this.rearYardList[0].eles[2].active = false;
  }

  /**
   * Method that handle the selection of the inclusionaryHousingCheck
   * @param event event value
   */
  handleInclusionaryHousingCheck(event: any) {
    this.inclusionaryHousingCheck = event?.target?.checked;
  }

  /**
   * Method that handle the sleected option checkbox
   * @param option option value
   * @param event event value
   * @param index index value
   * @param switchEvent switchEvent value
   */
  handleOptionsCheckbox(
    option?: any,
    event?: any,
    index?: number,
    switchEvent?: any
  ) {
    if (
      option?.includes('frontLotLine') ||
      event?.currentTarget?.id.includes('frontLotLine')
    ) {
      this.frontLotLines = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkFrontLotLine',
        event?.target?.checked || switchEvent
      );
      if (this.frontLotLines) {
      } else {
        sessionStorage.setItem('checkFrontLotLine', '');
      }
    } else if (
      option?.includes('sideLotLine') ||
      event?.currentTarget?.id.includes('sideLotLine')
    ) {
      this.sideLotLines = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkSideLotLine',
        event?.target?.checked || switchEvent
      );
      if (this.sideLotLines) {
      } else {
        sessionStorage.setItem('checkSideLotLine', '');
      }
    } else if (
      option?.includes('rearLotLine') ||
      event?.currentTarget?.id.includes('rearLotLine')
    ) {
      this.rearLotLine = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkRearLotLine',
        event?.target?.checked || switchEvent
      );
      if (this.rearLotLine) {
      } else {
        sessionStorage.setItem('checkRearLotLine', '');
      }
    } else if (
      option?.includes('cornerLotPortion') ||
      event?.currentTarget?.id.includes('cornerLotPortion')
    ) {
      this.cornerLotPortion = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkCornerLotPortion',
        event?.target?.checked || switchEvent
      );
      if (this.cornerLotPortion) {
      } else {
        sessionStorage.setItem('checkCornerLotPortion', '');
      }
    } else if (
      option?.includes('throughLotPortion') ||
      event?.currentTarget?.id.includes('throughLotPortion')
    ) {
      this.throughLotPortion = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkThroughLotPortion',
        event?.target?.checked || switchEvent
      );
      if (this.throughLotPortion) {
      } else {
        sessionStorage.setItem('checkThroughLotPortion', '');
      }
    } else if (
      option?.includes('interiorLotPortion') ||
      event?.currentTarget?.id.includes('interiorLotPortion')
    ) {
      this.interiorLotPortion = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkInteriorLotPortion',
        event?.target?.checked || switchEvent
      );
      if (this.interiorLotPortion) {
      } else {
        sessionStorage.setItem('checkInteriorLotPortion', '');
      }
    } else if (
      option?.includes('frontYard') ||
      event?.currentTarget?.id.includes('frontYard')
    ) {
      this.frontYard = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkFrontYard',
        event?.target?.checked || switchEvent
      );
      if (this.frontYard) {
      } else {
        sessionStorage.setItem('checkFrontYard', '');
      }
    } else if (
      option?.includes('sideYard') ||
      event?.currentTarget?.id.includes('sideYard')
    ) {
      this.sideYard = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkSideYard',
        event?.target?.checked || switchEvent
      );
      if (this.sideYard) {
      } else {
        sessionStorage.setItem('checkSideYard', '');
      }
    } else if (
      option?.includes('rearYard') ||
      event?.currentTarget?.id.includes('rearYard')
    ) {
      this.rearYard = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkRearYard',
        event?.target?.checked || switchEvent
      );
      if (this.rearYard) {
      } else {
        sessionStorage.setItem('checkRearYard', '');
      }
    }

    this.constructLayersList();
  }

  /**
   * Method that handle the selection of switch lot area
   * @param $event boolean
   */
  switchToggle(index: number, $event: any, oninitState?: boolean) {
    this.layersList[index].switch.enabled = $event;
    switch (this.layersList[index].optionName) {
      case 'wideStreet':
        sessionStorage.setItem('switchWideStreet', $event);
        break;
      case 'corner':
        sessionStorage.setItem('switchCorner', $event);
        break;
      case 'dimensionBlock':
        sessionStorage.setItem('switchDimensionBlock', $event);
        break;
      case 'dimensionBlock':
        sessionStorage.setItem('switchSetback', $event);
        break;
      case 'adjacent':
        sessionStorage.setItem('switchAdjacent', $event);
        break;
      case 'adjacentR5D':
        sessionStorage.setItem('switchAdjacentR5D', $event);
        break;
      case 'lotDimension':
        sessionStorage.setItem('switchLotDimension', $event);
        break;
      case 'lineType':
        sessionStorage.setItem('switchLotLine', $event);
        this.layersList[index].optionsList.forEach((ele: any) => {
          this.handleOptionsCheckbox(ele.optionName, null, index, $event);
        });
        break;
      case 'typePortion':
        sessionStorage.setItem('switchTypePortion', $event);
        this.layersList[index].optionsList.forEach((ele: any) => {
          this.handleOptionsCheckbox(ele.optionName, null, index, $event);
        });
        break;

      case 'yard':
        sessionStorage.setItem('switchYard', $event);
        this.layersList[index].optionsList.forEach((ele: any) => {
          this.handleOptionsCheckbox(ele.optionName, null, index, $event);
        });
        break;
      default:
        break;
    }
  }

  disabledAssembleOnProccess() {
    this.loadingGif = true;
    this.assembleIsActivated = false;
    this.enableAssembleBtn = false;
    this.tagBtnIsEnabled = false;
    this.firstEnvelope = false;
    this.useIsSelected = false;
    this.bulkIsSelected = false;
  }

  saveAllConfigurations() {
    const configEnvelopeObject = {
      bbl: sessionStorage.getItem('bbl'),
      useArrayConfig: this.useSelectionList,
      bulkArrayConfig: this.bulkList,
      rearYardArrayConfig: this.rearYardList,
      useArrayValueConfig: this.useArrayValue,
      useBulkAvailableInfoConfig: this.useBulkAvailableInfo,
      finalRequestAssemblerConfig: this.finalRequestAssembler,
      useBulkMethodAssembleConfig: this.useBulkMethods,
      visualizeYardState: this.visualizedYards,
    };
    sessionStorage.setItem(
      `za_envelope_${sessionStorage.getItem('bbl')}`,
      JSON.stringify(configEnvelopeObject)
    );
  }

  removeMemory() {
    const bblNumber = sessionStorage.getItem('bbl');
    const assembleKeys = [];
    const assembleComplete = [];
    sessionStorage.setItem('bbl', String(bblNumber));
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.includes('assemble')) {
        assembleKeys.push(key);
      }
    }
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.includes('za_envelope')) {
        assembleComplete.push(key);
      }
    }
    sessionStorage.removeItem('firstEnvelope');
    sessionStorage.removeItem('assembleIsActive');
    sessionStorage.removeItem('tagIsActivated');
    sessionStorage.removeItem('zoningAnalysisOptionsSelectedByBBL');
    assembleKeys.forEach((element: any) => {
      localStorage.removeItem(element);
    });
    assembleComplete.forEach((element: any) => {
      sessionStorage.removeItem(element);
      sessionStorage.setItem('assembleIsActive', 'false');
    });
    this.regulationsOptions = [];
  }

  /**
   * Method that setEnvelope
   */
  setEnvelope() {
  
    this.disabledAssembleOnProccess();
    this.setInvisibleLayer = true;
    setTimeout(() => {
      this.firstEnvelope = true;
      sessionStorage.setItem('firstEnvelope', String(this.firstEnvelope));
    }, 2000);
    const bblNumber = sessionStorage.getItem('bbl');
    if (
      this.useBulkAvailableInfo.length === this.finalRequestAssembler.length
    ) {
      this.zoningAnalysisService.setEnvelope(this.finalRequestAssembler, true);
    }
    this.saveAllConfigurations();
    setTimeout(() => {
      this.assembleIsActivated = true;
      //this.tagBtnIsEnabled = true;
      // const tagValue = sessionStorage.getItem('tagIsActivated');
      // if (tagValue === null || tagValue === undefined) {
      //   this.tagIsActivated = true;
      // } else if (tagValue === 'true') {
      //   this.tagIsActivated = true;
      // } else if (tagValue === 'false') {
      //   this.tagIsActivated = false;
      // }
      sessionStorage.setItem(
        'assembleIsActive',
        String(this.assembleIsActivated)
      );
    //  sessionStorage.setItem('tagIsActivated', String(this.tagIsActivated));
      this.constructConfigListAccordingBBL();
      this.setInvisibleLayer = false;
    }, 2000);
    this.disabledTemporalAssaemble = false;
  }

  /**
   * Method that set tag
   * @param value
   */
  setTag(value: boolean) {
    this.tagIsActivated = !this.tagIsActivated;
    this.setTagEnvelopeService.setTagEnvelope(this.tagIsActivated);
    sessionStorage.setItem('tagIsActivated', String(this.tagIsActivated));    
  }

  /**
   * Method that reset all config from ZA select use and bulk
   */
  resetSelectUseConfig() {
    const configArray = {
      bbl: sessionStorage.getItem('bbl'),
      useSelectionList: this.useSelectionList,
      useSecondList: this.useSecondList,
      useThirdList: this.useThirdList,
      bulkConfig: this.bulkList,
      btnAssemble: this.assembleIsActivated,
      btnTags: this.tagIsActivated,
      btnTagsEnabled: this.tagBtnIsEnabled,
      useIsSelected: this.useIsSelected,
      bulkIsSelected: this.bulkIsSelected,
    };

    if (this.selectUseSelectedConfig.includes('"')) {
      this.selectUseSelectedConfig = [];
      this.selectUseSelectedConfig?.push(configArray);
    } else {
      this.selectUseSelectedConfig = [];
      this.selectUseSelectedConfig.push(configArray);
    }

    sessionStorage.setItem(
      'zoningAnalysisOptionsSelectedByBBL',
      JSON.stringify(this.selectUseSelectedConfig)
    );
    sessionStorage.setItem('firstEnvelope', String(false));
    sessionStorage.setItem('tagIsActivated', String(false));
  }

  /**
   * Method that remove all envelope
   */
  removeEnvelope() {
    this.assembleIsActivated = false;
    this.enableAssembleBtn = false;
    this.tagIsActivated = false;
    this.tagBtnIsEnabled = false;
    this.tagIsActivated = false;
    this.firstEnvelope = false;
    this.useIsSelected = false;
    this.bulkIsSelected = false;
    this.zoningAnalysisService.removeEnvelope(true);
    this.bulkList = this.bulksListReseted;
    this.setUseRowsOriginalConfig();
    this.resetSelectUseConfig();
    this.resetRowsBulkList();
    this.resetRowsYardList();
    this.removeMemory();
    sessionStorage.removeItem('zoningAnalysisOptionsSelectedByBBL');
  }

  /**use table all envelope
   */
  openUseTable(zonedist: any) {
    console.log('useSelectionList', this.useSelectionList);
    this.zoningAnalysisService.openUseTable({ useTable: zonedist });
  }
}
