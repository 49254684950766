<section class="login_cnt">
  <section class="login">
    <figure class="logo_cnt">
      <img class="logo" src="assets/images/zlvas-logo.svg" alt="zlvas-logo" />
      <span class="beta">beta</span>
    </figure>

    <form class="form_cnt" (submit)="onSubmit()">
      <div class="input">
        <app-input
          class="input"
          [params]="username"
          (submitCallback)="onSubmit()"
          (blurCallback)="onFocusOutUsername($event)"
          (keyInputCallback)="resetMsgErr()"
        ></app-input>
      </div>

      <div class="input input--pass">
        <app-input
          class="input"
          [params]="pass"
          (submitCallback)="onSubmit()"
          (blurCallback)="onFocusOutPass($event)"
          (keyInputCallback)="resetMsgErr()"
        ></app-input>
      </div>

      <div class="check_cnt">
        <app-checkbox
          [id]="'checkbox-terms'"
          (onclick)="handleOptionsCheckbox($event)"
        ></app-checkbox>
        <label class="check_label">Terms of use</label>
      </div>
      <p class="term_text">
        The information provided by zlvas is intended for general informational
        purposes only. While we strive to ensure the accuracy and reliability of
        our platform, we cannot guarantee the completeness of the data. Users
        are advised to conduct their own due diligence and consult with
        professionals for specific zoning and feasibility studies. zlvas shall
        not be held liable for any inaccuracies, errors, or damages arising from
        the use of our platform.
      </p>
      <div class="row">
        <div class="col-12" *ngIf="msgError">
          <ngb-alert
            class="d-flex justify-content-center"
            type="danger"
            [dismissible]="false"
          >
            {{ msgError }}
          </ngb-alert>
        </div>
      </div>
      <div class="btn_cnt">
        <button class="btn_login" type="submit">Login</button>
      </div>
    </form>
  </section>
  <section class="footer_login ">
    <span>NYC zoning regulations pre 12/05/2024 (COYHO)</span>
  </section>
</section>
