<div class="contain">

<div class="modal-header">
  <h5>Zoning Use Groups</h5>
  <button type="button" class="close" (click)="closeModal()"></button>
</div>

<div class="modal-body">
  <div class="legend-container">
    <table class="legend-table">
      <tr>
        <td>● = Permitted</td>
        <td>◆ = Permitted with limitations</td>
        <td>○ = Special permit required</td>
        <td>– = Not permitted</td>
      </tr>
    </table><br>
    <table class="legend-table">
      <tr>
        <td>S = Size restriction</td>
        <td>P = Additional conditions</td>
        <td>U = Open use allowances</td>
      </tr>
    </table>
  </div>
  
  <div class="table-container">
    <table class="my-custom-table table ">
      <thead class="thead-dark sticky-top ">
        <tr>
          <th class="header-cell" *ngFor="let header of newHeaders; let i = index" 
              [ngClass]="{'up-highlighted-column': selectedZonedist === header}"
              [hidden]="header === 'ID'">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of newCsvData.slice(1); let isLast = last">
          <td *ngFor="let cell of row; let j = index" 
              [ngClass]="{
                'yellow-bg': row[ID_INDEX] === 't',  
                'dark-yellow-bg': row[ID_INDEX] === 'st',
                'brown-bg': row[ID_INDEX] === 'd',
                'highlighted-column': selectedZonedist === newHeaders[j],
                'last-row': selectedZonedist === newHeaders[j] && isLast
              }"
              [hidden]="j === ID_INDEX"> 
              <a *ngIf="urlData[cell]" [href]="urlData[cell]" target="_blank" class="custom-link">{{ cell }}</a>
            <span *ngIf="!urlData[cell]">{{ cell }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <!-- <table>
    <tr>
      <td><label for="filename">File name:</label></td>
      <td>
        <input
          type="text"
          id="filename"
          name="filename"
          [(ngModel)]="filename"
        />
      </td>
    </tr>
    <tr>
      <td><label for="filetype">Save as type:</label></td>
      <td>
        <select id="filetype" name="filetype" [(ngModel)]="extension">
          <option
            *ngFor="let filetype of filetypes"
            [value]="filetype.extension"
          >
            {{ filetype.name }}
          </option>
        </select>
      </td>
    </tr>
  </table> -->
  <!-- <button style="background-color: #f0d459;" type="button" class="btn" (click)="center()">Center</button> -->
  <table>
    <tr>
      <td><label for="filename"></label></td>
      <td>
        <!-- <input
          type="text"
          id="filename"
          name="filename"
          [(ngModel)]="filename"
        /> -->
      </td>
    </tr>
    <tr>
      <td><label for="filetype"></label></td>
      <td>
        <!-- <select id="filetype" name="filetype" [(ngModel)]="extension">
          <option
            *ngFor="let filetype of filetypes"
            [value]="filetype.extension"
          >
            {{ filetype.name }}
          </option>
        </select> -->
      </td>
    </tr>
  </table>
  <button type="button" class="btn btn-custom" (click)="downloadExcelFromMultipleTables()">
    Export
  </button>

  <!-- <button type="button" class="btn btn-custom" (click)="closeModal()">Close</button> -->
</div>
</div>